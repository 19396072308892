import React, { useEffect, useState, Fragment } from "react";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import {
  Table,
  Form,
  FormGroup,
  FormLabel,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getTokenFromSession } from "../services/authenticate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import { format } from "date-fns";

export const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = getTokenFromSession();
  const navigate = useNavigate();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = `0${d.getDate()}`.slice(-2);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchTransactions = async () => {
    if (!startDate || !endDate) {
      console.error("Both startDate and endDate must be selected.");
      return;
    }
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(
        `${config.api.transactions}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` ${token}`,
          },
        }
      );

      setTransactions(response.data || []);
    } catch (error) {
      if (error.response.status === 401) {
        sessionStorage.setItem("intendedPage", "/transaction");
        navigate("/login");
        return;
      }
      console.error("Error fetching transactions:", error.message);
      setTransactions([]);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      const initialStartDate = new Date(endDate);
      initialStartDate.setMonth(initialStartDate.getMonth() - 1);
      setStartDate(initialStartDate);
      setIsFirstLoad(false);
    }
  }, [endDate, isFirstLoad]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchTransactions();
    }
  }, [startDate, endDate, token]);

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const convertUnixTimestampToDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    return format(date, "yyyy-MM-dd hh:mm:ss a");
  };

  return (
    <Fragment>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div style={{ marginTop: "150px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Transactions", path: "/transactions" },
        ]}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="mb-4">
            <Form className="w-100">
              <FormGroup
                as={Row}
                className="align-items-center justify-content-center flex-wrap flex-md-nowrap"
              >
                <Col
                  xs={12}
                  md="auto"
                  className="d-flex align-items-center mb-3 mb-md-0"
                >
                  <FormLabel className="me-2 mb-0">Start Date:</FormLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    minDate={new Date("2024-10-01")}
                    maxDate={endDate}
                  />
                </Col>

                <Col
                  xs={12}
                  md="auto"
                  className="d-flex align-items-center mb-3 mb-md-0"
                >
                  <FormLabel className="me-2 mb-0">End Date:</FormLabel>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                </Col>

                <Col xs={12} md="auto" className="text-center text-md-start">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={fetchTransactions}
                  >
                    Fetch Transactions
                  </button>
                </Col>
              </FormGroup>
            </Form>
          </div>

          {transactions.length === 0 ? (
            <div
              style={{
                marginTop: "50px",
                marginBottom: "500px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              No transactions found
            </div>
          ) : (
            <div className="cart-main-area pt-90 pb-100">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="cart-page-title">Your Transactions</h3>
                </div>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="transaction-table"
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Order ID</th>
                      <th>Transaction Type</th>
                      <th>Amount</th>
                      <th>Hold</th>
                      <th>Open Balance</th>
                      <th>Close Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          {convertUnixTimestampToDate(
                            transaction.transactionTimeStamp
                          )}
                        </td>
                        <td>{transaction.orderId}</td>
                        <td>{transaction.transactionType}</td>
                        <td>{`₹${transaction.amount}`}</td>
                        <td>{`₹${transaction.hold}`}</td>
                        <td>{`₹${transaction.openBalance}`}</td>
                        <td>{`₹${transaction.closingBalance}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </>
      )}

      <div style={{ marginTop: "420px" }}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default TransactionTable;
