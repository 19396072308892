import React, { useState } from "react";
import { Modal, Button, Nav, Tab } from "react-bootstrap";

const Cookie = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Jingus privacy preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container defaultActiveKey="privacy">
          <div className="d-flex">
            {/* Sidebar Navigation */}
            <Nav variant="pills" className="flex-column me-4">
              <Nav.Item>
                <Nav.Link eventKey="privacy">Your Privacy</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="necessary">
                  Strictly Necessary Cookies
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="functional">Functional Cookies</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="targeting">Targeting Cookies</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* Tab Content */}
            <Tab.Content>
              <Tab.Pane eventKey="privacy">
                <h5>Your Privacy</h5>
                <p>
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="necessary">
                <h5>Strictly Necessary Cookies</h5>
                <p>
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms. You can set your
                  browser to block or alert you about these cookies, but some
                  parts of the site will not then work. These cookies do not
                  store any personally identifiable information..
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="functional">
                <h5>Functional Cookies</h5>
                <p>
                  These cookies enable the website to provide enhanced
                  functionality and personalisation. They may be set by us or by
                  third party providers whose services we have added to our
                  pages. If you do not allow these cookies then some or all of
                  these services may not function properly.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="targeting">
                <h5>Targeting Cookies</h5>
                <p>
                  These cookies may be set through our site by our advertising
                  partners. They may be used by those companies to build a
                  profile of your interests, to measure the effectiveness of
                  marketing campaigns, and show you relevant adverts on other
                  sites. They do not store directly personal information, but
                  are based on uniquely identifying your browser and internet
                  device. If you do not allow these cookies. you will experience
                  less targeted advertising.
                </p>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Save Settings
        </Button>
        <Button variant="success" onClick={handleClose}>
          Accept All Cookies
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Cookie;
