import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../css/Footer.css";

const Footer = () => {
  const currrentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <Container>
        {/* Main Footer Links */}
        <Row className="footer-container custom-row">
          {/* Links Section */}
          <Col xs={12} md={6} className="footer-column links-column">
            <ul className="footer-list">
              <li>
                <Link to="/aboutus">About us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/jingus_books?utm_source=qr&igsh=M2JqOGx4YmM5dzh2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Policies Section */}
        <Row className="footer-container custom-row">
          <Col xs={12} className="footer-column">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="/terms_condtn" className="text-muted">
                  Terms & Conditions
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/privacy_policy" className="text-muted">
                  Privacy Policy
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/refund_policy" className="text-muted">
                  Refund Policy
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/return_policy" className="text-muted">
                  Return Policy
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/shipping_policy" className="text-muted">
                  Shipping Policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="footer-container custom-row">
          <Col className="copy-right">
            <span>© {currrentYear} Jingus Systems. All Rights Reserved.</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
