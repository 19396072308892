import React, { useState, useEffect, useMemo } from "react";
import DisplayBooksGrid from "./DisplayBooksGrid";
import { Link, useNavigate, useParams } from "react-router-dom";
import SidebarMenuTwo from "../Header/SideBarMenuTwo";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import config from "../Config/config";
import { useDispatch } from "react-redux";
import { add, clearBorrowCart } from "../Store/BorrowCart";
import { addBuy, clearBuyCart } from "../Store/BuyCart";
import axiosInstance from "../axiosInstance";

const ViewMorePage = ({
  books,
  ageCategories,
  selectedAges,
  setSelectedAges,
  genres,
  selectedGenres,
  setSelectedGenres,
  selectedConditions,
  setSelectedConditions,
  searchTerm,
  setSearchTerm,

  staffBooks,
}) => {
  const { book } = useParams(); // Fetching route parameter
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Determine which set of books to display

  const [crumb, setCrumb] = useState("All Books");
  const items = useMemo(() => {
    if (book === "book") return books;
    if (book === "staff") {
      setCrumb("Staff Picks");
      return staffBooks;
    }
  }, [book, books, staffBooks]);

  const [filteredBooks, setFilteredBooks] = useState(items);
  const token = getTokenFromSession();
  useEffect(() => {
    const addItemsToCart = async (token) => {
      try {
        const response = await axiosInstance.get(`${config.api.cart}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        const body = await response.data;

        dispatch(clearBorrowCart());
        dispatch(clearBuyCart());

        body.forEach((items) => {
          const quantity = 1;
          if (items.transactionType === "LENDING") {
            dispatch(add({ items, quantity }));
          } else if (items.transactionType === "PURCHASE") {
            dispatch(addBuy({ items, quantity }));
          }
        });
      } catch (error) {
        if (error.response) {
          console.log("Error Status:", error.response.status);
          if (error.response.status === 401 || error.response.status === 403) {
            console.log("Unauthorized or forbidden. Attempting token refresh.");
            const refersToken = getRefreshToken();
            try {
              if (refersToken === null) {
                return;
              }
              await refreshIdToken(refersToken);
              const newToken = getTokenFromSession();
              await addItemsToCart(newToken);
            } catch (refreshError) {
              navigate("/login");
            }
          }
        } else {
          console.log("Error Message:", error.message);
        }
      }
    };

    addItemsToCart(token);
  }, [token, navigate, dispatch]);
  useEffect(() => {
    let updatedBooks = items;
    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedAges.includes(book.ageGroup)
      );
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedGenres.includes(book.genre)
      );
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedConditions.includes(book.condition)
      );
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(
        (book) =>
          (book.title &&
            book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (book.author &&
            book.author.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredBooks(updatedBooks);
  }, [selectedAges, selectedGenres, selectedConditions, searchTerm, items]);

  return (
    <div>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div className="menu-bar">
        <SidebarMenuTwo
          genres={genres}
          ageCategories={ageCategories}
          selectedAges={selectedAges}
          onAgeSelect={setSelectedAges}
          selectedGenres={selectedGenres}
          onGenreSelect={setSelectedGenres}
          selectedConditions={selectedConditions}
          onConditionSelect={setSelectedConditions}
          setFilteredBooks={setFilteredBooks}
          filteredBooks={filteredBooks}
        />
      </div>
      <div style={{ marginTop: "180px" }}>
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            { label: `${crumb}`, path: "/view-more/book" },
          ]}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          padding: "20px",
          width: "100%",
          height: "350px",
        }}
      >
        {filteredBooks.length > 0 ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              overflowY: "auto",
            }}
          >
            <DisplayBooksGrid books={filteredBooks} />
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <span>
                No book was found. Please click the link below to request the
                book.
              </span>
              <Link to="/request" style={{ marginTop: "30px" }}>
                RequestBook
              </Link>
            </div>
            <div style={{ marginTop: "100px" }}>
              <Footer />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewMorePage;
