import { useDispatch, useSelector } from "react-redux";
import {
  remove,
  increaseQuantity,
  decreaseQuantity,
  isAvailable,
  clearBorrowCart,
  add,
} from "../Store/BorrowCart";
import {
  buyRemove,
  buyIncreaseQuantity,
  buyDecreaseQuantity,
  buyIsAvailable,
  clearBuyCart,
  addBuy,
} from "../Store/BuyCart";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Footer from "../assets/Footer";
import { Fragment, useContext, useEffect, useState } from "react";
import Breadcrumb from "../wrapper/Breadcrumb";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { AmountContext } from "../Context/AmountContext";

const indianStates = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  {
    value: "Andaman and Nicobar Islands",
    label: "Andaman and Nicobar Islands",
  },
  { value: "Chandigarh", label: "Chandigarh" },
  {
    value: "Dadra and Nagar Haveli and Daman and Diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Delhi", label: "Delhi" },
  { value: "Puducherry", label: "Puducherry" },
  { value: "Ladakh", label: "Ladakh" },
  { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
];

export const Cart = ({ searchTerm, onSearch, books }) => {
  const { setOrderAmount, setWalletBalance, setRemainingAmount } = useContext(
    AmountContext
  );
  const [loading, setLoading] = useState(false);
  const token = getTokenFromSession();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [orderIds, setOrderIds] = useState(null);
  const [forwardPrice, setForwardPrice] = useState(0);
  const [reversePrice, setReversePrice] = useState(0);
  const borrowBooks = useSelector((state) => state.BorrowCart.items) || [];
  const buyingBooks = useSelector((state) => state.BuyCart.items) || [];

  const buyingAmount = useSelector((state) => state.BuyCart.Amount);
  const cautionDeposit = borrowBooks.reduce((total, book) => {
    return total + parseInt(book.sellingPrice || 0);
  }, 0);

  let sno = 0;
  const hasItems = borrowBooks.length > 0 || buyingBooks.length > 0;

  const borrowWeight = useSelector((state) => state.BorrowCart.Weight) || 0;
  const buyingWeight = useSelector((state) => state.BuyCart.Weight) || 0;
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [address, setAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    name: null,
    mobile: null,
    addressLine: null,
    city: null,
    state: null,
    landmark: null,
    pincode: null,
  });

  const [shippingInfo, setShippingInfo] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [found, SetFound] = useState(false);
  const [shippingRate, setShippingRate] = useState([]);
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [closoingBalance, setClosingBalance] = useState(0);

  useEffect(() => {
    const callMethod = async (token) => {
      await addItemsToCart(token);
      await getAddress(token);
      await getClosingBalance(token);
    };
    callMethod(token);
  }, [token, navigate]);

  useEffect(() => {
    if (orderIds && found) {
      payMent();
    }
  }, [orderIds]);

  useEffect(() => {
    const checkAddressComplete = () => {
      setIsAddressComplete(
        newAddress.name &&
          newAddress.mobile &&
          newAddress.addressLine &&
          newAddress.city &&
          newAddress.state &&
          newAddress.pincode
      );
    };

    checkAddressComplete();
  }, [newAddress]);

  useEffect(() => {
    if (!newAddress.pincode) {
      return;
    }
    shippingAmount();
  }, [address, buyingWeight, borrowWeight]);

  const addItemsToCart = async (token) => {
    try {
      const response = await axiosInstance.get(`${config.api.cart}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const body = await response.data;

      dispatch(clearBorrowCart());
      dispatch(clearBuyCart());

      body.forEach((items) => {
        const quantity = 1;
        if (items.transactionType === "LENDING") {
          dispatch(add({ items, quantity }));
        } else if (items.transactionType === "PURCHASE") {
          dispatch(addBuy({ items, quantity }));
        }
      });
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("Unauthorized or forbidden. Attempting token refresh.");
          const refersToken = getRefreshToken();
          try {
            if (refersToken === null) {
              return;
            }
            await refreshIdToken(refersToken);
            const newToken = getTokenFromSession();
            await addItemsToCart(newToken);
          } catch (refreshError) {
            sessionStorage.setItem("intendedPage", "/cart");
            navigate("/login");
          }
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const handleCheckout = async () => {
    if (!isAddressComplete) {
      alert("Please fill out all the fields in the address.");
      return;
    }
    await Checkout();
  };
  const Checkout = async () => {
    try {
      const response = await axiosInstance.post(
        `${config.api.checkout}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const result = await response.data;

      if (
        result.transactionTypes.length > 0 &&
        result.unavailableISBNs.length > 0
      ) {
        let count = 0;
        result.transactionTypes.forEach((element) => {
          if (element === "PURCHASE") {
            dispatch(buyIsAvailable(result.unavailableISBNs[count]));
            count++;
          } else {
            dispatch(isAvailable(result.unavailableISBNs[count]));
            count++;
          }
        });
        return;
      }
      setOrderIds(result.order_id);
      localStorage.setItem("orderId", result.order_id);
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const removeSingleItem = async (stockid) => {
    try {
      await axiosInstance.delete(`${config.api.cart}?stockId=${stockid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("hello");
          const refersToken = getRefreshToken();
          if (refersToken === null) {
            return;
          }
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const cleanCart = async () => {
    try {
      await axiosInstance.delete(`${config.api.cart}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      localStorage.removeItem("borrowCartState");
      localStorage.removeItem("buyCartState");
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("hello");
          const refersToken = getRefreshToken();
          if (refersToken === null) {
            return;
          }
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const getAddress = async (token) => {
    try {
      const response = await axiosInstance.get(`${config.api.address}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = response.data;
      if (!data) {
        setShowAddressModal(true); // Show modal if no address is found
      } else {
        setAddress(data); // Set the single address\
        setNewAddress({
          name: data.name || null,
          mobile: data.phoneNumber || null,
          addressLine: data.shippingAddress || null,
          city: data.city || null,
          state: data.state || null,
          landmark: data.landMark || null,
          pincode: data.pinCode || null,
        });
      }
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          if (refersToken === null) {
            return;
          }
          await refreshIdToken(refersToken)
            .then(() => {
              let token = getTokenFromSession();
              getAddress(token);
            })
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const addAddress = async () => {
    try {
      await axiosInstance.post(
        `${config.api.address}`,
        {
          user_name: newAddress.name,
          shipping_address: newAddress.addressLine,
          city: newAddress.city,
          state: newAddress.state,
          pin_code: newAddress.pincode,
          land_mark: newAddress.landmark,
          phone_number: newAddress.mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      await getAddress();
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => addAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const editAddress = async () => {
    try {
      await axiosInstance.put(
        `${config.api.address}`,
        {
          user_name: newAddress.name,
          shipping_address: newAddress.addressLine,
          city: newAddress.city,
          state: newAddress.state,
          pin_code: newAddress.pincode,
          land_mark: newAddress.landmark,
          phone_number: newAddress.mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      await getAddress();
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("hello");
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const payMent = async () => {
    try {
      const response = await axiosInstance.get(
        `${config.api.payment}?orderId=${orderIds}&pgName=PhonePE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const data = response.data;
      if (data.orderAmount) {
        setOrderAmount(data.orderAmount);
        setWalletBalance(data.closingBalance);
        setRemainingAmount(data.remainingAmount);
        // setTimeout(() => {
        //   navigate("/payment_status");
        // }, 1000);
        navigate("/payment_status");
      } else {
        localStorage.setItem(
          "merchantTransactionId",
          data.merchantTransactionId
        );
        const url = data.paymentLink;
        window.location.href = url;
      }
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("hello");
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const shippingAmount = async () => {
    setLoading(true);
    try {
      const { addressLine, city, state, landmark, pincode } = newAddress;

      // Concatenate the address fields into a single address string
      const fullAddress = `${addressLine}, ${landmark}, ${city}, ${state}`;

      // Construct the query string
      const queryParams = new URLSearchParams({
        pinCode: pincode,
        address: fullAddress,
      }).toString();

      const response = await fetch(`${config.api.shippingRate}?${queryParams}`);

      if (response.status === 204) {
        setShippingInfo(
          "No shipping information available for this pin code. Please change the address."
        );
        setLoading(false);
        return;
      }

      if (response.ok) {
        const info = await response.json();
        const bookingWeight = buyingWeight + borrowWeight;
        setShippingRate(info.shippingRates);
        const shippingPrice = calculateShippingPrice(
          bookingWeight,
          info.shippingRates,
          borrowWeight
        );
        SetFound(true);
        setShippingPrice(shippingPrice);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("hello");
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => editAddress())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getClosingBalance = async (token) => {
    try {
      const response = await axiosInstance.get(`${config.api.transactions}`, {
        headers: {
          "Content-Type": "applictaion/json",
          Authorization: `${token}`,
        },
      });
      const value = response.data ? response.data.closingBalance : 0;
      setClosingBalance(value);
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          await refreshIdToken(refersToken)
            .then(() => {
              const token = getTokenFromSession();
              getClosingBalance(token);
            })
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/cart");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };
  const borrowQuantity = (quantity, bookQuantity, cartItem) => {
    if (bookQuantity < quantity) {
      dispatch(increaseQuantity(cartItem));
    } else {
      setToastMessage(`Only ${quantity} is available`);
      setShow(true);
    }
  };

  const buyQuantity = (quantity, bookQuantity, cartItem) => {
    if (bookQuantity < quantity) {
      dispatch(buyIncreaseQuantity(cartItem));
    } else {
      setToastMessage(`Only ${quantity} is available`);
      setShow(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleStateChange = (selectedOption) => {
    setNewAddress((prev) => ({ ...prev, state: selectedOption.value }));
  };

  const handleSaveAddress = (e) => {
    e.preventDefault();
    if (address) {
      editAddress();
    } else {
      addAddress();
    }
    setShowAddressModal(false);
  };

  const calculateShippingPrice = (weight, shippingRates, lendingweight) => {
    if (!weight || isNaN(weight)) {
      console.error("Invalid weight value:", weight);
      return "Invalid weight";
    }

    if (!shippingRates || shippingRates.length === 0) {
      console.error("No shipping rates available.");
      return "Shipping rates not found";
    }
    let reversePrice = 0;
    if (lendingweight !== 0) {
      reversePrice = reverseWeight(lendingweight, shippingRates);
    }
    let forwardPrice = fordWardWeight(weight, shippingRates);
    setForwardPrice(forwardPrice);
    setReversePrice(reversePrice);
    let totalPrice = forwardPrice + reversePrice;
    return totalPrice;
  };

  const fordWardWeight = (weight, shippingRate) => {
    let price = 0;
    for (const rate of shippingRate) {
      if (weight >= rate.fromWeight && weight <= rate.toWeight) {
        return parseInt(rate.forwardPrice);
      }
    }
    return price !== 0
      ? price
      : parseInt(shippingRate[shippingRate.length - 1].forwardPrice);
  };

  const reverseWeight = (lendingweight, shippingRate) => {
    let price = 0;
    for (const rate of shippingRate) {
      if (lendingweight >= rate.fromWeight && lendingweight <= rate.toWeight) {
        return parseInt(rate.reversePrice);
      }
    }
    return price !== 0
      ? price
      : shippingRate[setShippingRate.length - 1].reversePrice;
  };
  return (
    <Fragment>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={onSearch} />
      <div style={{ marginTop: "150px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Cart", path: "/cart" },
        ]}
      />
      {/* Toast component for showing auto-hide notifications */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Row>
          <Col xs={6} style={{ margin: "3px" }}>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Notification</strong>
              </Toast.Header>
              <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
          </Col>
        </Row>
      </div>

      {/* Only display the Cart if no search term is being used */}

      <div className="cart-main-area pt-90 pb-100">
        <div className="container">
          {hasItems ? (
            <Fragment>
              <h3 className="cart-page-title">Your cart items</h3>
              <div className="row">
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <table>
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Borrow Price</th>
                          <th>CautionDeposit</th>
                          <th>Buy Price</th>
                          <th>Qty</th>
                          <th>Subtotal</th>
                          <th>Weight (ingrams)</th>

                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {borrowBooks.map((cartItem, key) => (
                          <tr key={key}>
                            <td className="product-name">{++sno}</td>
                            <td className="product-thumbnail">
                              <img
                                className="img-fluid"
                                src={cartItem.imageUrl}
                                alt=""
                              />
                              {cartItem.available !== "available" && (
                                <span style={{ color: "red" }}>
                                  book is not available remove it from the cart
                                </span>
                              )}
                            </td>
                            <td className="product-name">{cartItem.title}</td>
                            <td className="product-price-cart">
                              <span className="amount">
                                ₹{cartItem.lendingPricePerDay}
                              </span>
                            </td>
                            <td className="product-price-cart">
                              <span className="amount">
                                ₹{cartItem.sellingPrice}
                              </span>
                            </td>
                            <td className="product-price-cart">-</td>
                            <td className="product-quantity">
                              {/* <div className="cart-plus-minus">
                                  <button
                                    className="dec qtybutton"
                                    onClick={() =>
                                      dispatch(decreaseQuantity(cartItem))
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="cart-plus-minus-box"
                                    type="text"
                                    value={cartItem.quantity}
                                    readOnly
                                  />
                                  <button
                                    className="inc qtybutton"
                                    onClick={() =>
                                      borrowQuantity(
                                        cartItem.stock_id.length,
                                        cartItem.quantity,
                                        cartItem
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div> */}
                              1
                            </td>
                            <td className="product-subtotal">
                              ₹{cartItem.lendingPricePerDay * cartItem.quantity}
                            </td>
                            <td className="product-subtotal">
                              {cartItem.weight}
                            </td>
                            <td className="product-remove">
                              <button
                                onClick={() => {
                                  dispatch(remove(cartItem));
                                  removeSingleItem(cartItem.stockId);
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </button>
                            </td>
                          </tr>
                        ))}

                        {buyingBooks.map((cartItem, key) => (
                          <tr key={key}>
                            <td className="product-name">{++sno}</td>
                            <td className="product-thumbnail">
                              <img
                                className="img-fluid"
                                src={cartItem.imageUrl}
                                alt=""
                              />
                              {cartItem.available !== "available" && (
                                <span style={{ color: "red" }}>
                                  book is not available remove it from the cart
                                </span>
                              )}
                            </td>
                            <td className="product-name">{cartItem.title}</td>
                            <td className="product-price-cart">-</td>
                            <td className="product-price-cart"> - </td>
                            <td className="product-price-cart">
                              <span className="amount">
                                ₹{cartItem.sellingPrice}
                              </span>
                            </td>
                            <td className="product-quantity">
                              {/* <div className="cart-plus-minus">
                                  <button
                                    className="dec qtybutton"
                                    onClick={() =>
                                      dispatch(buyDecreaseQuantity(cartItem))
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="cart-plus-minus-box"
                                    type="text"
                                    value={cartItem.quantity}
                                    readOnly
                                  />
                                  <button
                                    className="inc qtybutton"
                                    onClick={() =>
                                      buyQuantity(
                                        cartItem.stock_id.length,
                                        cartItem.quantity,
                                        cartItem
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div> */}
                              1
                            </td>
                            <td className="product-subtotal">
                              ₹{cartItem.sellingPrice * cartItem.quantity}
                            </td>
                            <td className="product-subtotal">
                              {cartItem.weight}
                            </td>
                            <td className="product-remove">
                              <button
                                onClick={() => {
                                  dispatch(buyRemove(cartItem));
                                  removeSingleItem(cartItem.stockId);
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-shiping-update-wrapper">
                    <div className="cart-clear">
                      <button
                        onClick={() => {
                          handleCheckout();
                        }}
                      >
                        Checkout
                      </button>
                    </div>
                    <div className="cart-shiping-update">
                      <Link to="/">Continue Shopping</Link>
                    </div>
                    <div className="cart-clear">
                      <button
                        onClick={() => {
                          cleanCart();
                        }}
                      >
                        Clear Shopping Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Cart Total Section */}
                <div className="col-lg-4 col-md-12 mb-4">
                  <div className="grand-totall">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Cart Total
                      </h4>
                    </div>
                    <h5>
                      Refundable Caution Deposit <span>₹{cautionDeposit}</span>
                    </h5>
                    <h5>
                      Buying Price <span>₹{buyingAmount}</span>
                    </h5>
                    <h5>
                      Forward Shipping Price <span>₹{forwardPrice}</span>
                    </h5>
                    <h5>
                      Reverse Shipping Price <span>₹{reversePrice}</span>
                    </h5>
                    <h5>
                      Grand Shipping Price <span>₹{shippingPrice}</span>
                    </h5>
                    <h5>
                      Total
                      <span>
                        ₹{cautionDeposit + buyingAmount + shippingPrice}
                      </span>
                    </h5>
                    <h5>
                      Wallet Balance <span>₹{closoingBalance}</span>
                    </h5>
                    <h5>
                      Grand Total{" "}
                      <span>
                        ₹
                        {Math.max(
                          0,
                          cautionDeposit +
                            buyingAmount +
                            shippingPrice -
                            closoingBalance
                        )}
                      </span>
                    </h5>
                    <h5>
                      Remaining Wallet Balance
                      <span>
                        ₹
                        {Math.max(
                          0,
                          closoingBalance -
                            (cautionDeposit + buyingAmount + shippingPrice)
                        )}
                      </span>
                    </h5>
                    <h5>
                      Grand Total Weight{" "}
                      <span>{buyingWeight + borrowWeight}(in Grams)</span>
                    </h5>
                    {/* <button
                        onClick={() => {
                          handleCheckout();
                        }}
                        className="whatsapp-button"
                      >
                        Checkout
                      </button> */}
                  </div>
                </div>

                {/* Address Section */}
                <div className="col-lg-4 col-md-12">
                  <Card className="mb-4">
                    <Card.Header>Your Address</Card.Header>
                    {address ? (
                      <Card.Body>
                        <span>{!found && shippingInfo}</span>
                        <br />
                        <strong>{address.name}</strong>
                        <br />
                        {address.shippingAddress}, {address.city},{" "}
                        {address.state}, {address.pinCode}
                        <br />
                        <span>{address.phoneNumber}</span>
                        <br />
                        <Button
                          className="mt-2"
                          variant="primary"
                          onClick={() => {
                            setShowAddressModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <p>No address found. Please add a new address.</p>
                        <Button
                          variant="primary"
                          onClick={() => setShowAddressModal(true)}
                        >
                          Add New Address
                        </Button>
                      </Card.Body>
                    )}
                  </Card>
                </div>

                {/* Third Column Section */}
                <div className="col-lg-4 col-md-12 mb-4">
                  <div className="table-content table-responsive">
                    <h3 className="cart-page-title">
                      Shipping Price For Pincode {newAddress.pincode}
                    </h3>
                    {loading ? (
                      <div className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : shippingRate.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              Weight
                              <br />
                              <span style={{ fontSize: "11px" }}>
                                (In Grams)
                              </span>
                            </th>
                            <th>
                              Forward Price <br />
                              <span></span>
                            </th>
                            <th>
                              Reverse Price <br />
                              <span style={{ fontSize: "11px" }}>
                                (Lending Only)
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {shippingRate.map((value, index) => (
                            <tr key={index}>
                              <td>
                                {value.fromWeight} to {value.toWeight}
                              </td>
                              <td>₹{value.forwardPrice}</td>
                              <td>₹{value.reversePrice}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>
                        No shipping rates available. Please try another pin
                        code.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Modal
                  show={showAddressModal}
                  onHide={() => setShowAddressModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add New Address</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleSaveAddress}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={newAddress.name}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="mobile">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={newAddress.mobile}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="addressLine">
                        <Form.Label>Address Line</Form.Label>
                        <Form.Control
                          type="text"
                          name="addressLine"
                          value={newAddress.addressLine}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          value={newAddress.city}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="state">
                        <Form.Label>State</Form.Label>
                        <Select
                          options={indianStates}
                          value={indianStates.find(
                            (option) => option.value === newAddress.state
                          )}
                          onChange={handleStateChange}
                          placeholder="Select State"
                          required
                          isClearable
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="landmark">
                        <Form.Label>Landmark</Form.Label>
                        <Form.Control
                          type="text"
                          name="landmark"
                          value={newAddress.landmark}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="pincode">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Control
                          type="text"
                          name="pincode"
                          value={newAddress.pincode}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Save Address
                      </Button>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowAddressModal(false)}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Fragment>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-cart"></i>
                  </div>
                  <div
                    className="item-empty-area__text"
                    style={{ marginTop: "200px", marginBottom: "420px" }}
                  >
                    No items found in cart <br />
                    <Link to="/">Add Items</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}></div>
      <Footer />
    </Fragment>
  );
};
