import React, { useEffect, useState } from "react";
import { Card, Modal, Table } from "react-bootstrap";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { addBuy } from "../Store/BuyCart";
import { add } from "../Store/BorrowCart";
import { useDispatch } from "react-redux";
import { getTokenFromSession } from "../services/authenticate";

const Model = ({ book, show, handleShow }) => {
  const [descrpition, setDescription] = useState();
  const [buySuccess, setBuySuccess] = useState(false);
  const [borrowSuccess, setBorrowSuccess] = useState(false);
  const [isImgError, setIsImgError] = useState(false);
  const handleError = () => setIsImgError(true);
  const dispatch = useDispatch();

  const handleAddToCart = (actionType) => {
    const items = book;
    if (actionType === "buy") {
      dispatch(addBuy({ items, quantity }));
      setBuySuccess(true);
      setTimeout(() => setBuySuccess(false), 1000);
    } else if (actionType === "borrow") {
      dispatch(add({ items, quantity }));
      setBorrowSuccess(true);
      setTimeout(() => setBorrowSuccess(false), 1000);
    }
  };
  const quantity = 1;
  const addcart = async (transaction_type) => {
    const token = getTokenFromSession();
    try {
      const response = await fetch(`${config.api.cart}`, {
        method: "POST",
        headers: {
          "Content-Type": `application/json`,
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          stock_id: book.stockId[0],
          isbn: book.isbn,
          transaction_type,
          lending_days_count: transaction_type === "LENDING" ? 1 : 0,
        }),
      });

      if (!response.ok) {
        console.error(
          "Error with transaction:",
          transaction_type,
          response.status
        );
      }
    } catch (error) {
      console.log("Error Message:", error.message);
    }
  };

  useEffect(() => {
    const getDescription = async (isbn) => {
      try {
        const response = await axiosInstance.get(
          `${config.api.book}?isbn=${isbn}`,
          {
            headers: {
              "Content-Type": "application.json",
            },
          }
        );
        const data = await response.data;
        console.log(data);
        setDescription(data);
      } catch (error) {
        console.error(error);
      }
    };
    getDescription(book.isbn);
  }, [book]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const imageStyles = {
    width: "100%", // Ensures the image takes full width
    maxWidth: windowWidth <= 600 ? "200px" : "392px", // Adjust maxWidth based on screen size
    height: "auto", // Adjust height automatically
    borderRadius: "5px",
    margin: "0 auto",
    display: "block",
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="xl" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div style={{ padding: "20px", marginTop: "50px" }}>
            <Card
              style={{ maxWidth: "1200px", margin: "0 auto", border: "none" }}
            >
              {descrpition && (
                <Card.Title
                  style={{
                    fontSize: "1.5rem",
                    marginBottom: "20px",
                    marginTop: "20px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {book.title}
                </Card.Title>
              )}
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
                className="d-md-flex flex-md-row align-items-md-start"
              >
                {!isImgError ? (
                  <LazyLoadImage
                    variant="top"
                    src={book.imageUrl}
                    alt={book.title}
                    // style={{
                    //   width: "100%",
                    //   maxWidth: "392px",
                    //   height: descrpition ? "598px" : "500px",
                    //   borderRadius: "5px",
                    //   margin: "0 auto",
                    //   display: "block",
                    // }}
                    style={imageStyles}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "598px",
                      backgroundColor: "#f8f9fa",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#333",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {book.title}
                    </span>
                  </div>
                )}
                <div style={{ flex: "1" }}>
                  {descrpition ? (
                    <>
                      <Card.Title
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          textAlign: "left",
                        }}
                      >
                        Description:
                      </Card.Title>
                      <Card.Text style={{ fontSize: "1.0rem" }}>
                        {descrpition}
                      </Card.Text>
                    </>
                  ) : (
                    <Card.Title
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "20px",
                        marginTop: "50px",
                      }}
                    >
                      {book.title}
                    </Card.Title>
                  )}

                  <Table hover borderless>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Author</strong>
                        </td>
                        <td>{book.author}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>ISBN</strong>
                        </td>
                        <td>{book.isbn}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Genre</strong>
                        </td>
                        <td>{book.genre}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>AgeCategory</strong>
                        </td>
                        <td>{book.ageGroup}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Condition</strong>
                        </td>
                        <td>{book.condition}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Price</strong>
                        </td>
                        <td>₹{book.sellingPrice}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Lending Price (per day)</strong>
                        </td>
                        <td>₹{book.lendingPricePerDay}</td>
                      </tr>
                    </tbody>
                    <tr>
                      <td>
                        <p style={{ fontSize: "1rem", margin: "0" }}>
                          <strong>Buy:</strong>
                          {buySuccess ? (
                            <span
                              style={{ marginLeft: "10px", color: "green" }}
                            >
                              Item added to cart!
                            </span>
                          ) : (
                            <button
                              onClick={() => {
                                addcart("PURCHASE");
                                handleAddToCart("buy");
                              }}
                              style={{
                                border: "none",
                                backgroundColor: "white",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FontAwesomeIcon icon={faCartShopping} />
                            </button>
                          )}
                        </p>
                      </td>
                      <td>
                        <p style={{ fontSize: "1rem", margin: "0" }}>
                          <strong>Borrow:</strong>
                          {borrowSuccess ? (
                            <span
                              style={{ marginLeft: "10px", color: "green" }}
                            >
                              Item added to cart!
                            </span>
                          ) : (
                            <button
                              onClick={() => {
                                addcart("LENDING");
                                handleAddToCart("borrow");
                              }}
                              style={{
                                border: "none",
                                backgroundColor: "white",
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FontAwesomeIcon icon={faCartShopping} />
                            </button>
                          )}
                        </p>
                      </td>
                    </tr>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Model;
