import React from "react";
import Header from "../Header/Header";
import SearchBar from "../Components/SearchBar";
import SideBarMenuOne from "../Header/SideBarMenuOne";

const HeaderComponent = ({ searchTerm, handleSearch, genres }) => {
  return (
    <>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={handleSearch} />
      <div className="menu-bar">
        <SideBarMenuOne genres={genres} />
      </div>
    </>
  );
};

export default HeaderComponent;
