import React, { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import HeaderComponent from "./HeaderComponent";
import { useNavigate } from "react-router-dom";
import Footer from "../assets/Footer";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";

const RequestBook = ({ genres, searchTerm, setSearchTerm }) => {
  const phoneNumber =
    useSelector((state) => state.userInformation.phoneNumber) || "";
  const email = useSelector((state) => state.userInformation.email) || "";

  const [formData, setFormData] = useState({
    title: "",
    author: "",
    isbn: "",
    phone: phoneNumber,
    email: email,
    requestType: "",
    notes: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setAlertMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.phone && !formData.email) {
      setAlertMessage(
        "Please fill all required fields and provide either a phone number or an email."
      );
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${config.api.request}`,
        formData
      );
      if (response.status === 200) {
        setSuccessMessage("Book Request has been successfully submitted!");
        setFormData({
          title: "",
          author: "",
          isbn: "",
          phone: "",
          email: "",
          requestType: "",
          notes: "", // Clear notes as well
        });
      }
    } catch (err) {
      console.error(err);
      setAlertMessage("An error occurred while submitting the request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <HeaderComponent
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          genres={genres}
        />
      </div>
      <Container style={{ maxWidth: "600px", marginTop: "250px" }}>
        <div style={{ display: "flex" }}>
          <FaArrowLeft
            style={{
              cursor: "pointer",
              marginRight: "20px",
              marginTop: "10px",
            }}
            onClick={() => navigate(-1)}
          />
          <h2 className="text-center d-flex align-items-center">
            Submit Book Information
          </h2>
        </div>
        {alertMessage && (
          <Alert
            variant="danger"
            onClose={() => setAlertMessage("")}
            dismissible
          >
            {alertMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert
            variant="success"
            onClose={() => setSuccessMessage("")}
            dismissible
          >
            {successMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Book Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              placeholder="Enter book title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Author</Form.Label>
            <Form.Control
              type="text"
              name="author"
              placeholder="Enter author name"
              value={formData.author}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>ISBN</Form.Label>
            <Form.Control
              type="text"
              name="isbn"
              placeholder="Enter ISBN"
              value={formData.isbn}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder="Enter phone number"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Do you want to buy or rent this book?</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                label="Buy"
                name="requestType"
                value="Buy"
                checked={formData.requestType === "Buy"}
                onChange={(e) =>
                  setFormData({ ...formData, requestType: e.target.value })
                }
                required
              />
              <Form.Check
                inline
                type="radio"
                label="Rent"
                name="requestType"
                value="Rent"
                checked={formData.requestType === "Rent"}
                onChange={(e) =>
                  setFormData({ ...formData, requestType: e.target.value })
                }
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              rows={3}
              placeholder="Enter additional details or comments about the book"
              value={formData.notes}
              onChange={handleChange}
            />
          </Form.Group>

          <Button
            style={{
              color: "white",
              border: "none",
              alignItems: "center",
            }}
            type="submit"
            className="w-40"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Request New Book"}
          </Button>
        </Form>
      </Container>
      <div style={{ marginTop: "50px" }}>
        <Footer />
      </div>
    </>
  );
};

export default RequestBook;
