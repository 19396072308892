import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import getMetaData from '../utils/getMetaData';


const SEO = () => {
  const location = useLocation();
  const meta = getMetaData(location.pathname);
  return (
    <>
      {process.env.REACT_APP_ENV === 'production' && (
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="keywords" content={meta.keywords} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:description" content={meta.description} />
          <link rel="canonical" href={meta.url} />
        </Helmet>)}
    </>
  );
};

export default SEO;