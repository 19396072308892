import React, { useEffect, useState } from "react";
import { Form, Button, Container, Alert, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authenticate, getTokenFromSession } from "../services/authenticate";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import Footer from "../assets/Footer";
import { useDispatch, useSelector } from "react-redux";
import config from "../Config/config";
import axiosInstance from "../axiosInstance";
import { faS } from "@fortawesome/free-solid-svg-icons";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    phoneNumberErr: "",
    passwordErr: "",
    loginErr: "",
  });
  const location = useLocation();
  const [resendOtpTimer, setResendOtpTimer] = useState(60);

  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");

  const [loading, setLoading] = useState(false); // State to track loading
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  // Get borrow and buy cart items from Redux store
  const borrowBooks = useSelector((state) => state.BorrowCart.items) || [];
  const buyingBooks = useSelector((state) => state.BuyCart.items) || [];

  useEffect(() => {
    if (step === 2 && resendOtpTimer > 0) {
      const timer = setTimeout(() => {
        setResendOtpTimer((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [resendOtpTimer, step]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let phoneNumberErr = "";
    let passwordErr = "";

    if (formData.phoneNumber === "") {
      phoneNumberErr = "Phone Number is required";
    }
    if (formData.password === "") {
      passwordErr = "Password is required";
    }

    if (phoneNumberErr || passwordErr) {
      setErrors({ ...errors, phoneNumberErr, passwordErr });
      return false;
    }
    return true;
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP");
      return;
    }
    axiosInstance
      .post(`${config.api.otp}`, {
        phone_number: formData.phoneNumber,
        otp: otp,
      })
      .then((res) => {
        if (res.status === 202) {
          location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(err.response?.data || "An unexpected error occurred");
      });
  };

  const sendOtp = (phone) => {
    axiosInstance
      .get(`${config.api.otp}?number=${phone}`)
      .then((res) => {
        if (res.status === 200) {
          setResendOtpTimer(60);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ phoneNumberErr: "", passwordErr: "", loginErr: "" });
    setLoading(true); // Start loading state

    let formattedPhoneNumber = formData.phoneNumber.trim();
    if (!formattedPhoneNumber.startsWith("+91")) {
      formattedPhoneNumber = `+91${formattedPhoneNumber}`;
    }
    setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
    if (validateForm()) {
      try {
        await authenticate(formattedPhoneNumber, formData.password, dispatch);
        localStorage.setItem("user", formData.phoneNumber);
        const token = getTokenFromSession();

        const addAllItemsToCart = async (token) => {
          try {
            for (const items of borrowBooks) {
              await addItemToCart(items, "LENDING", token);
            }

            for (const items of buyingBooks) {
              await addItemToCart(items, "PURCHASE", token);
            }
          } catch (error) {
            console.error("Error in adding all items to cart:", error.message);
          }
        };

        await addAllItemsToCart(token);

        const intendedPage = sessionStorage.getItem("intendedPage");
        if (intendedPage) {
          sessionStorage.removeItem("intendedPage");
          navigate(intendedPage);
          return;
        }

        navigate("/");
      } catch (err) {
        console.log(err.message);
        if (err.message === "User is not confirmed.") {
          setErrors({
            ...errors,
            loginErr:
              "The user already exists but is not verified. If this account belongs to you, please verify it to proceed.",
          });
        }
        if (err.message === "Incorrect username or password.") {
          setErrors({
            ...errors,
            loginErr: "Incorrect username or password.",
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const addItemToCart = async (items, transactionType, token) => {
    try {
      const response = await fetch(`${config.api.cart}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` ${token}`,
        },
        body: JSON.stringify({
          stock_id: items.stockId,
          isbn: items.isbn,
          transaction_type: transactionType,
          lending_days_count: transactionType === "LENDING" ? 1 : 0,
        }),
      });

      if (!response.ok) {
        console.error(
          "Error with transaction:",
          transactionType,
          response.status
        );
        return;
      }

      console.log("Item successfully added to cart:", items.isbn);
    } catch (error) {
      console.error("Error sending item to cart:", error.message);
    }
  };

  return (
    <div>
      <Header />
      <SearchBar />
      {step === 1 && (
        <>
          <Container style={{ maxWidth: "400px", marginTop: "300px" }}>
            <h2 className="text-center mb-4">Login</h2>
            {errors.loginErr && (
              <Alert variant="danger">{errors.loginErr}</Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formphoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  isInvalid={!!errors.phoneNumberErr}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumberErr}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  isInvalid={!!errors.passwordErr}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordErr}
                </Form.Control.Feedback>
              </Form.Group>

              {errors.loginErr.includes("The user already exists") ? (
                <>
                  <Button
                    type="submit"
                    className="mt-3"
                    style={{ marginRight: "10px" }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Login"}
                  </Button>
                  <Button
                    type="button"
                    className="mt-3"
                    onClick={() => {
                      setStep(2);
                      sendOtp(formData.phoneNumber);
                    }}
                  >
                    Verify
                  </Button>
                </>
              ) : (
                <Button type="submit" className="mt-3" disabled={loading}>
                  {loading ? "Loading..." : "Login"}
                </Button>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {/* New Account Section */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>New Account</span>
                  <Link to="/signup">Sign Up</Link>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/forgetPassword"
                    style={{ textDecoration: "none", marginRight: "10px" }}
                  >
                    ForgetPassword
                  </Link>
                </div>
              </div>
            </Form>
          </Container>
          <div style={{ marginTop: "210px" }}></div>
          <Footer />
        </>
      )}
      {step === 2 && (
        <>
          <Container style={{ maxWidth: "300px", marginTop: "300px" }}>
            {errorMessage && (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                {errorMessage}
              </Alert>
            )}
            <Form>
              <p>The OTP will be sent to this number {formData.phoneNumber}</p>
              <Form.Group controlId="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="mt-3"
                onClick={handleOtpSubmit}
              >
                Verify OTP
              </Button>
            </Form>
            {resendOtpTimer === 0 ? (
              <Button
                variant="link"
                className="mt-3"
                onClick={() => sendOtp(formData.phoneNumber)}
              >
                Resend OTP
              </Button>
            ) : (
              <p className="text-muted mt-3">
                Resend OTP in {resendOtpTimer} seconds
              </p>
            )}
          </Container>
          <div style={{ marginTop: "350px" }}>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;
