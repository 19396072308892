import React from "react";
import { NavDropdown } from "react-bootstrap";
import AgeCategory from "./AgeCategory";
import Conditions from "./Conditions";
import Genre from "./Genre";
import SortingPrice from "./SortingPrice";
import "../css/Filter.css";
const FilterComponent = ({
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  selectedConditions,
  onConditionSelect,
  ageCategories,
  genres,
  toggleSidebar,
  setFilteredBooks,
  filteredBooks,
}) => {
  return (
    <div style={{ marginTop: "100px" }}>
      <strong>Filter</strong>
      <NavDropdown
        title="Age Category"
        id="basic-nav-dropdown"
        className="filter"
      >
        <NavDropdown.Item>
          <AgeCategory
            selectedAges={selectedAges}
            onSelect={onAgeSelect}
            ageCategories={ageCategories}
            toggleSidebar={toggleSidebar}
          />
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Genre" id="basic-nav-dropdown" className="filter">
        <NavDropdown.Item>
          <Genre
            genres={genres}
            selectedGenres={selectedGenres}
            onSelect={onGenreSelect}
            toggleSidebar={toggleSidebar}
          />
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Condition" id="basic-nav-dropdown" className="filter">
        <NavDropdown.Item>
          <Conditions
            selectedConditions={selectedConditions}
            onSelect={onConditionSelect}
            toggleSidebar={toggleSidebar}
          />
        </NavDropdown.Item>
      </NavDropdown>

      <SortingPrice
        setFilteredBooks={setFilteredBooks}
        filteredBooks={filteredBooks}
      />
    </div>
  );
};

export default FilterComponent;
