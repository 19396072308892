import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";

export default function SwiperComponent() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        style={{
          width: "100%",
          height: "100%",
        }}
        slidesPerView={1}
        loop={true}
      >
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url('/1.png')`,
            backgroundSize: "cover", // Default for larger screens
            backgroundPosition: "center",
            height: "100%",
          }}
        />
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url('/2.png')`,
            backgroundSize: "cover", // Default for larger screens
            backgroundPosition: "center",
            height: "100%",
          }}
        />
        {/* Other slides */}
      </Swiper>
    </div>
  );
}
