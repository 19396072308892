import {
  AuthenticationDetails,
  CognitoUser,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import userpool from "../userpool";
import { addInformation } from "../Store/UserInformation";

export const authenticate = (PhoneNumber, Password, dispatch) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: PhoneNumber,
      Pool: userpool,
    });

    const authDetails = new AuthenticationDetails({
      PhoneNumber: PhoneNumber,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();
        storeTokenInSession(idToken, refreshToken);
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const refreshIdToken = (refreshToken) => {
  return new Promise((resolve, reject) => {
    const user = userpool.getCurrentUser();
    if (!user || !refreshToken) {
      console.error(
        "No user or refresh token available for refreshing ID token."
      );
      reject(new Error("No refresh token available"));
      return;
    }

    try {
      const refreshTokenObj = new CognitoRefreshToken({
        RefreshToken: refreshToken,
      });

      user.refreshSession(refreshTokenObj, (err, session) => {
        if (err) {
          console.error("Error refreshing session:", err.message || err);
          reject(err);
        } else {
          const newIdToken = session.getIdToken().getJwtToken();
          const newRefreshToken = session.getRefreshToken().getToken();
          storeTokenInSession(newIdToken, newRefreshToken);
          resolve(newIdToken);
        }
      });
    } catch (error) {
      console.error(
        "Unexpected error during token refresh:",
        error.message || error
      );
      reject(error);
    }
  });
};

export const logout = () => {
  const user = userpool.getCurrentUser();
  sessionStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  if (!user) {
    console.error("No user is currently logged in.");
    window.location.href = "/";
    return;
  }
  user.signOut();

  window.location.href = "/";
};

// Function to store token in user session
const storeTokenInSession = (accessToken, refreshToken) => {
  sessionStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

// Function to get token from user session
export const getTokenFromSession = () => {
  return sessionStorage.getItem("accessToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

