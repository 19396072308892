const config = {
  booksUrl: process.env.REACT_APP_BOOKS_URL,
  staffPicksUrl: process.env.REACT_APP_STAFF_PICKS_URL,
  basedOnYourViewUrl: process.env.REACT_APP_BOV_URL,
  imageUrlBase: process.env.REACT_APP_IMAGE_URL_BASE,
  api: {
    cart: process.env.REACT_APP_CART_URL,
    checkout: process.env.REACT_APP_CHECKOUT_URL,
    orders: process.env.REACT_APP_ORDERS_URL,
    transactions: process.env.REACT_APP_TRANSACTIONS_URL,
    returnBook: process.env.REACT_APP_RETURN_BOOK_URL,
    address: process.env.REACT_APP_ADDRESS_URL,
    payment: process.env.REACT_APP_PAYMENT_URL,
    request: process.env.REACT_APP_REQUEST_URL,
    shippingRate: process.env.REACT_APP_SHIPPING_RATE_URL,
    otp: process.env.REACT_APP_OTP_URL,
    book: process.env.REACT_APP_BOOK_URL,
    aibot: process.env.REACT_APP_AIBOT_URL,
  },
};

export default config;
