import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { add } from "../Store/BorrowCart";
import { addBuy } from "../Store/BuyCart";
import { getTokenFromSession } from "../services/authenticate";

import config from "../Config/config";
import { useState } from "react";

const Price = ({ lendingPricePerDay, sellingPrice, book }) => {
  const dispatch = useDispatch();
  const quantity = 1;

  const token = getTokenFromSession();
  const items = book;
  const [itemAdded, setItemAdded] = useState(false);

  const addcart = async (transaction_type) => {
    try {
      const response = await fetch(`${config.api.cart}`, {
        method: "POST",
        headers: {
          "Content-Type": `application/json`,
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          stock_id: book.stockId[0],
          isbn: book.isbn,
          transaction_type,
          lending_days_count: transaction_type === "LENDING" ? 1 : 0,
        }),
      });
      // Briefly show "Item added to cart" message
      setItemAdded(true);
      setTimeout(() => {
        setItemAdded(false);
      }, 1000);

      if (!response.ok) {
        console.error(
          "Error with transaction:",
          transaction_type,
          response.status
        );
      }
    } catch (error) {
      console.log("Error Message:", error.message);
    }
  };

  return (
    <div style={{ textAlign: "center", color: "#555" }}>
      {itemAdded ? (
        <p style={{ fontSize: "1rem", margin: "0" }}>
          <strong>Item added to cart!</strong>
        </p>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2px 0",
            }}
          >
            <p
              style={{
                fontSize: "1rem",
                margin: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>Mrp:</strong> <s>{book.mrp}</s>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2px 0",
            }}
          >
            <p
              style={{
                fontSize: "1rem",
                margin: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>Borrow:</strong> ₹{lendingPricePerDay}
              <span> /day</span>
              <button
                onClick={() => {
                  dispatch(add({ items, quantity }));
                  addcart("LENDING");
                }}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faCartShopping} />
              </button>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "2px 0",
            }}
          >
            <p
              style={{
                fontSize: "1rem",
                margin: "0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong>Buy:</strong> ₹{sellingPrice}
              <button
                onClick={() => {
                  dispatch(addBuy({ items, quantity }));
                  addcart("PURCHASE");
                }}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faCartShopping} />
              </button>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Price;
