const getMetaData = (pathname) => {
  switch (pathname) {
    case '/genre-based-books/Fiction':
      return {
        title: 'Fiction Books | Jingus Online Library',
        description: 'Explore and rent the best fiction books from Jingus. A world of stories awaits!',
        keywords: 'fiction books, novels, online book rental',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/Fiction`,
      };
    case '/genre-based-books/Kids':
      return {
        title: 'Kids Books | Jingus Online Library',
        description: 'Find engaging and educational books for kids at Jingus. Rent or buy now!',
        keywords: 'kids books, board books, children\'s stories',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/Kids`,
      };
    case '/genre-based-books/YoungAdult':
      return {
        title: 'Young Adult Books | Jingus Online Library',
        description: 'Explore the world of young adult fiction at Jingus. Discover new authors and stories that resonate with you!',
        keywords: 'young adult books, YA fiction, teen novels',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/YoungAdult`,
      };
    case '/genre-based-books/BoardBooks':
      return {
        title: 'Board Books for Kids | Jingus Online Library',
        description: 'Introduce your little ones to the world of reading with our collection of board books at Jingus!',
        keywords: 'board books, kids books, children\'s stories',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/BoardBooks`,
      };
    case '/genre-based-books/SelfHelp':
      return {
        title: 'Self-Help Books | Jingus Online Library',
        description: 'Transform your life with our collection of self-help books at Jingus. Discover new perspectives and strategies for success!',
        keywords: 'self-help books, personal development, motivation',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/SelfHelp`,
      };
    case '/genre-based-books/NonFiction':
      return {
        title: 'Non-Fiction Books | Jingus Online Library',
        description: 'Discover new knowledge and insights with our collection of non-fiction books at Jingus!',
        keywords: 'non-fiction books, biographies, self-help',
        url: `${process.env.REACT_APP_API_URL}genre-based-books/NonFiction`,
      };
    default:
      return {
        title: 'Jingus Online Book Library | Buy & Rent Books',
        description: 'Discover the joy of reading. Rent or buy fiction, non-fiction, kids books, and more!',
        keywords: 'online book library, buy books, rent books',
        url: `${process.env.REACT_APP_API_URL}`,
      };
  }
};

export default getMetaData;