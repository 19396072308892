import React, { Fragment, useEffect, useState } from "react";
import { getTokenFromSession } from "../services/authenticate";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import { Button, Table, Spinner } from "react-bootstrap";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";

const ReturnBooks = () => {
  const [lendingOrder, setLendingOrder] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const token = getTokenFromSession();
  let sno = 0;

  const getLendingBook = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(config.api.returnBook, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      setLendingOrder(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.setItem("intendedPage", "/returnBook");
        navigate("/login");
        return;
      }
      console.error("Error:", error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getLendingBook();
  }, [navigate]);

  const returnSingleBook = async (lendingOrderId, stockID) => {
    try {
      const response = await axiosInstance.post(
        `${config.api.returnBook}?lendingOrderId=${lendingOrderId}&stockId=${stockID}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Book has been successfully returned");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.setItem("intendedPage", "/returnBooks");
        navigate("/login");
        return;
      }
      console.error("Error returning single book:", error);
    }
  };

  const returnAllBook = async (orderID) => {
    try {
      const response = await axiosInstance.post(
        `${config.api.returnBook}?orderID=${orderID}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Books have been successfully returned");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.setItem("intendedPage", "/returnBooks");
        navigate("/login");
        return;
      }
      console.error("Error returning all books:", error);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const calculateDaysRented = (lendingStartDate) => {
    const startDate = new Date(lendingStartDate);
    const today = new Date();
    const timeDifference = today.getTime() - startDate.getTime();
    const daysRented = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysRented;
  };

  const calculateTotalRent = (lendingPricePerDay, lendingStartDate) => {
    const daysRented = calculateDaysRented(lendingStartDate);
    return lendingPricePerDay * daysRented;
  };

  return (
    <Fragment>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div style={{ marginTop: "150px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "ReturnBook", path: "/returnBook" },
        ]}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : lendingOrder.length === 0 ? (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "600px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No order found
        </div>
      ) : (
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="cart-page-title">Rental details</h3>
            </div>
            <Table
              striped
              bordered
              hover
              responsive
              className="transaction-table"
            >
              <thead>
                <tr>
                  <th>sno</th>
                  <th>LendingOrder Id</th>
                  <th>StockId</th>
                  <th>ISBN</th>
                  <th>Title</th>
                  <th>BorrowPrice/day</th>
                  <th>BorrowDate</th>
                  <th>No of rental days</th>
                  <th>TotalRentAmount</th>
                  <th>Return date</th>
                  <th>Return</th>
                </tr>
              </thead>
              <tbody>
                {lendingOrder.map((order) => (
                  <tr key={order.lendingOrderId}>
                    <td>{++sno}</td>
                    <td>{order.lendingOrderId}</td>
                    <td>{order.stockId}</td>
                    <td>{order.isbn}</td>
                    <td>{order.title || "Title"}</td>
                    <td>₹{order.lendingPricePerDay}</td>
                    <td>{formatDate(order.lendingStartDate)}</td>
                    <td>₹{calculateDaysRented(order.lendingStartDate)}</td>
                    <td>
                      ₹
                      {calculateTotalRent(
                        order.lendingPricePerDay,
                        order.lendingStartDate
                      )}
                    </td>
                    <td>{formatDate(new Date())}</td>
                    <td>
                      <Button
                        style={{
                          backgroundColor: "gray",
                          textDecoration: "none",
                          borderColor: "gray",
                        }}
                        onClick={() => {
                          returnSingleBook(order.lendingOrderId, order.stockId);
                        }}
                      >
                        Return
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <div className="cart-shiping-update-wrapper">
                <div className="cart-shiping-update"></div>
                <div className="cart-clear">
                  <button
                    onClick={() => {
                      lendingOrder.forEach((order) =>
                        returnAllBook(order.orderId)
                      );
                    }}
                  >
                    Return All Book
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "380px" }}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default ReturnBooks;
