import { createSlice } from "@reduxjs/toolkit";
import config from "../Config/config";

const initialState = () => {
  const savedState = JSON.parse(localStorage.getItem("borrowCartState"));
  return savedState || { items: [], Amount: 0, Weight: 0 };
};

const BorrowCart = createSlice({
  name: "BorrowCart",
  initialState: initialState(),
  reducers: {
    add(state, action) {
      const { items, quantity } = action.payload;
      const available = "available";
      const stockId = Array.isArray(items.stockId)
        ? items.stockId[0]
        : items.stockId;
      const existingBook = state.items.find((item) => item.isbn === items.isbn);
      if (existingBook) {
        return;
      } else {
        let imageUrl = `${config.imageUrlBase}${items.isbn}.jpg`;
        state.items.push({ ...items, quantity, available, imageUrl, stockId });
        state.Amount += quantity * parseInt(items.lendingPricePerDay, 10);
        state.Weight += quantity * parseInt(items.weight, 10);
      }

      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
    remove(state, action) {
      const updatedItems = state.items.filter(
        (item) => item.isbn !== action.payload.isbn
      );
      const removedItem = state.items.find(
        (item) => item.isbn === action.payload.isbn
      );
      const newAmount =
        state.Amount -
        parseInt(removedItem.lendingPricePerDay, 10) * removedItem.quantity;
      const newWeight =
        state.Weight - parseInt(removedItem.weight, 10) * removedItem.quantity;
      state.items = updatedItems;
      state.Amount = newAmount;
      state.Weight = newWeight;
      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
    increaseQuantity(state, action) {
      const book = state.items.find(
        (item) => item.isbn === action.payload.isbn
      );
      if (book) {
        book.quantity += 1;
        state.Amount += parseInt(book.lendingPricePerDay, 10);
        state.Weight += parseInt(book.weight, 10);
        localStorage.setItem("borrowCartState", JSON.stringify(state));
      }
    },
    decreaseQuantity(state, action) {
      const book = state.items.find(
        (item) => item.isbn === action.payload.isbn
      );
      if (book && book.quantity > 1) {
        book.quantity -= 1;
        state.Amount -= parseInt(book.lendingPricePerDay, 10);
        state.Weight -= parseInt(book.weight, 10);
        localStorage.setItem("borrowCartState", JSON.stringify(state));
      }
    },
    isAvailable(state, action) {
      const book = state.items.find((item) => item.isbn === action.payload);
      book.available = "notAvailable";
      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
    clearBorrowCart(state) {
      state.items = [];
      state.Amount = 0;
      state.Weight = 0;
      localStorage.setItem("borrowCartState", JSON.stringify(state));
    },
  },
});

export const {
  add,
  remove,
  increaseQuantity,
  decreaseQuantity,
  isAvailable,
  clearBorrowCart,
} = BorrowCart.actions;
export const Borrowreducers = BorrowCart.reducer;
