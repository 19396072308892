import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => {
  const savedDetails = JSON.parse(localStorage.getItem("userInformation"));
  return savedDetails || { phoneNumber: "", email: "" };
};

const userInformation = createSlice({
  name: "userInformation",
  initialState: getInitialState(),
  reducers: {
    addInformation(state, action) {
      const { phone, email } = action.payload;
      state.phoneNumber = phone;
      state.email = email;
      localStorage.setItem("userInformation", JSON.stringify(state));
    },
  },
});

export const { addInformation } = userInformation.actions;

export const userInformationReducer = userInformation.reducer;
