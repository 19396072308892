import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, NavDropdown } from "react-bootstrap";
import { getTokenFromSession, logout } from "../services/authenticate";
import { useLocation } from "react-router-dom";
const SearchBar = ({ searchTerm, onSearch }) => {
  const borrowProduct = useSelector((state) => state.BorrowCart.items);
  const buyProduct = useSelector((state) => state.BuyCart.items);
  const totalItems = borrowProduct.length + buyProduct.length;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const token = getTokenFromSession();
  const log = () => {
    logout();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "10px",
        borderBottom: "1px solid #ddd",
        position: "fixed",
        top: "35px",
        left: 0,
        right: 0,
        boxSizing: "border-box",
        zIndex: 1001,
        height: "60px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        maxWidth: "100%",
      }}
    >
      <Link to="/">
        <img
          src="/jingus-logo1.png"
          alt="Logo"
          style={{
            width: isMobile ? "80px" : "150px",
            height: "100px",
            objectFit: "contain",
            marginRight: isMobile ? "10px" : "20px",
          }}
        />
      </Link>
      {!(
        location.pathname === "/cart" ||
        location.pathname === "/returnBook" ||
        location.pathname === "/order" ||
        location.pathname === "/transaction"
      ) && (
        <div
          style={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            position: "relative",
            minWidth: "150px",
            maxWidth: isMobile ? "300px" : "600px",
            marginRight: "10px",
          }}
        >
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Search by title or author"
            style={{
              flex: "1",
              padding: "5px 30px 5px 10px",
              fontSize: isMobile ? "12px" : "14px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              color: "#333",
              width: "100%",
            }}
          />
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: isMobile ? "14px" : "16px",
              color: "#333",
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Arial, sans-serif",
          fontSize: isMobile ? "16px" : "20px",
          color: "#333",
        }}
      >
        <Container>
          <NavDropdown
            title={<FontAwesomeIcon icon={faUser} style={{ color: "black" }} />}
            id="user-nav-dropdown"
            style={{ zIndex: 1002, position: "relative" }}
          >
            {!token && (
              <>
                <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                <NavDropdown.Item href="/signup">SignUp</NavDropdown.Item>
              </>
            )}

            {token && (
              <>
                <span
                  style={{
                    marginLeft: "18px",
                    color: "#3c3c3c",
                    fontSize: "14px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: "black", marginRight: "1px" }}
                  />
                  {localStorage.getItem("user")}
                </span>

                <NavDropdown.Item href="/order">Order</NavDropdown.Item>
                <NavDropdown.Item href="/transaction">
                  Accounts
                </NavDropdown.Item>
                <NavDropdown.Item href="/returnBook">
                  ReturnBooks
                </NavDropdown.Item>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    color: "black",
                    marginLeft: "8px",
                  }}
                  onClick={log}
                >
                  Log out
                </Button>
              </>
            )}
          </NavDropdown>
        </Container>

        <div style={{ marginLeft: "10px", position: "relative" }}>
          <Link to="/cart">
            <button
              style={{
                border: "none",
                backgroundColor: "white",
                position: "relative",
              }}
            >
              <FontAwesomeIcon icon={faCartShopping} />
              {totalItems > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                    backgroundColor: "red",
                    color: "white",
                    fontSize: "12px",
                    borderRadius: "50%",
                    padding: "2px 6px",
                  }}
                >
                  {totalItems}
                </span>
              )}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
