// src/axiosInstance.js
import axios from "axios";
import NProgress from "nprogress";
import "./css/nprogress.css";

NProgress.configure({ showSpinner: true });
// Create an Axios instance
const axiosInstance = axios.create();

// Function to set up Axios interceptors
export const setupAxiosInterceptors = (setError) => {
  // Request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      NProgress.start();
      return config;
    },
    (error) => {
      NProgress.done();
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      NProgress.done();
      return response;
    },
    (error) => {
      NProgress.done();
      if (error.response) {
        const status = error.response.status;
        if (status >= 500 && status < 600) {
          setError({ type: "500", message: "Internal Server Error" });
        }
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
