import React from "react";
import WhatsAppButton from "../WhatsAppButton";

const Header = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "6px",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000, // Ensures the header stays above other content
        color: "white", // Text color for visibility
        display: "flex", // Apply flexbox
        justifyContent: "flex-end", // Align content to the right
        alignItems: "center", // Center items vertically
      }}
    >
      {/* <button style={{ fontSize: "15px", marginRight: "10px", border:"none" ,backgroundColor:"black", color:"white"}}>Track your order |</button> */}
      <span
        style={{
          fontSize: "15px",
          paddingRight: "10px",
          border: "none",
          backgroundColor: "black",
          color: "white",
        }}
      >
        <WhatsAppButton />
      </span>
    </div>
  );
};

export default Header;
