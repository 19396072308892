import React, { Fragment } from "react";
import HeaderComponent from "../Components/HeaderComponent"; // Updated import
import Breadcrumb from "../wrapper/Breadcrumb"; 
import Footer from './Footer';  
import { useNavigate } from 'react-router-dom';

const AboutUs = ({ searchTerm, setSearchTerm, genres }) => {
    const navigate = useNavigate();
    
    const handleSearch = (term) => {
        setSearchTerm(term);
        navigate('/view-more/book');
    };
 
    return (
        <Fragment>
            <HeaderComponent 
                searchTerm={searchTerm} 
                handleSearch={handleSearch} 
                genres={genres} 
            /> {/* Using HeaderComponent with props */}
            
            <div style={{ marginTop: "180px" }}></div>
    

            <Breadcrumb
                pages={[
                    { label: "Home", path: "/" },
                    { label: "About Us", path: "/about" }, // Adjust path if needed
                ]}
            />
            
            <div className="container my-5">
                <h2>About Us</h2>
                <p>
                    Jingus is an innovative online platform that makes accessing and enjoying physical books both easy and affordable. With our extensive catalog and personalized reading recommendations, you can browse, order, and have books delivered right to your doorstep. And when you're done, we’ll pick them up! For those who cherish the tangible experience of holding a book – flipping through pages, the smell of paper – Jingus is the perfect solution, offering what digital books cannot.
                </p>
                <p>
                    But that’s not all. With Jingus, you only pay for what you read. After returning to India, I, Sowmya, found it hard to justify costly library subscriptions that required regular payments, regardless of how much we read. Some months, we needed more books; other times, none at all. Keeping up with a subscription felt wasteful and expensive. Jingus solves this problem by offering a flexible, pay-as-you-go service – no subscriptions, no unnecessary costs.
                </p>
                <p>
                    For those who wish to own their favorite books, Jingus also offers a minimum 30% discount on purchases, making it easier and more affordable to build your personal library.
                </p>
                <p>
                    Whether you want to borrow or buy, Jingus gives you the freedom to enjoy books without the hassle of maintaining a subscription. We cater to both seasoned readers and children just beginning their reading journey, offering curated collections across various genres.
                </p>
                <p>
                    Jingus was born out of my personal experience. As a mother of voracious readers, I saw my children thrive with access to diverse literature while we lived abroad. However, back in India, I struggled to find well-stocked libraries that didn’t require expensive subscriptions. So, I created Jingus – to bring the joy of reading to every home, minus the cost and complexity.
                </p>
                <p>
                    Discover. Read. Save. Welcome to the future of book borrowing with Jingus!
                </p>
            </div>
            
            <div style={{ marginTop: "100px" }}>
                <Footer />
            </div>
        </Fragment>
    );
};

export default AboutUs;
