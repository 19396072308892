import React, { createContext, useState } from "react";
export const AmountContext = createContext();

export const AmountProvider = ({ children }) => {
  const [orderAmount, setOrderAmount] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [remainingAmount, setRemainingAmount] = useState(null);

  const value = {
    orderAmount,
    setOrderAmount,
    walletBalance,
    setWalletBalance,
    remainingAmount,
    setRemainingAmount,
  };
  return (
    <AmountContext.Provider value={value}>{children}</AmountContext.Provider>
  );
};
