import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import "../styles.css";
import { Stack } from "react-bootstrap";
import HeaderComponent from "./HeaderComponent"; // Updated import
import SwiperComponent from "./SwiperComponent";
import "swiper/swiper-bundle.css";
import Staffpicks from "./StaffPicks";
import GenreComponents from "./GenreComponents";

import Footer from "../assets/Footer";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import { clearBorrowCart, add } from "../Store/BorrowCart";
import { addBuy, clearBuyCart } from "../Store/BuyCart";
import config from "../Config/config";
import { Link, useNavigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import axiosInstance from "../axiosInstance";
const Home = ({ books, genres, searchTerm, staffpicks, setSearchTerm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getTokenFromSession();
  const refersToken = getRefreshToken();

  useEffect(() => {
    addItemsToCart(token);
  }, [token, refersToken]);
  const addItemsToCart = async (token) => {
    try {
      const response = await axiosInstance.get(`${config.api.cart}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const body = await response.data;

      dispatch(clearBorrowCart());
      dispatch(clearBuyCart());

      body.forEach((items) => {
        const quantity = 1;
        if (items.transactionType === "LENDING") {
          dispatch(add({ items, quantity }));
        } else if (items.transactionType === "PURCHASE") {
          dispatch(addBuy({ items, quantity }));
        }
      });
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("Unauthorized or forbidden. Attempting token refresh.");
          const refersToken = getRefreshToken();
          try {
            if (refersToken === null) {
              return;
            }
            await refreshIdToken(refersToken);
            const newToken = getTokenFromSession();
            await addItemsToCart(newToken);
          } catch (refreshError) {
            sessionStorage.setItem("intendedPage", "/cart");
            navigate("/login");
          }
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  return (
    <Stack>
      <HeaderComponent
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        genres={genres}
      />{" "}
      {/* Using HeaderComponent with props */}
      <div
        style={{
          marginTop: "170px",
          backgroundColor: "#f0f0f0",
          width: "100%",
          height: "200px",
        }}
        className="swiper-container"
      >
        <SwiperComponent />
      </div>
      <div>
        <GenreComponents books={books} genres={genres} />
      </div>
      <div>
        <Staffpicks books={staffpicks} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Footer />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="privacy policy"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          fontSize: "13px",
          backgroundColor: "#3c3c3c",
        }}
      >
        Jingus.in uses cookies to offer you a better user experience. By
        clicking on "I understand" you agree to the storing of cookies on your
        device in accordance with our{" "}
        {<Link to="/privacy_policy">privacypolicy</Link>}
      </CookieConsent>
    </Stack>
  );
};

export default Home;
