import React, { useState, useEffect } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import userpool from "../userpool";
import Footer from "../assets/Footer";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import HeaderComponent from "./HeaderComponent";

const SignupPage = ({ searchTerm, setSearchTerm, genres }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [number, setNumber] = useState();
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(60);

  useEffect(() => {
    if (step === 2 && resendOtpTimer > 0) {
      const timer = setTimeout(() => {
        setResendOtpTimer((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [resendOtpTimer, step]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (formData.phoneNumber === "" && formData.password === "") {
        setPhoneNumberErr("PhoneNumber is required");
        setPasswordErr("Password is required");
        resolve({
          email: "PhoneNumber is required",
          password: "Password is required",
        });
      } else if (formData.phoneNumber === "") {
        setPhoneNumberErr("PhoneNumber is required");
        resolve({ email: "PhoneNumber is required", password: "" });
      } else if (formData.password === "") {
        setPasswordErr("Password is required");
        resolve({ email: "", password: "Password is required" });
      } else if (formData.password.length < 6) {
        setPasswordErr("Password must be 6 characters");
        resolve({ email: "", password: "Password must be 6 characters" });
      } else {
        resolve({ email: "", password: "" });
      }
      reject("");
    });
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP");
      return;
    }
    axiosInstance
      .post(`${config.api.otp}`, {
        phone_number: number,
        otp: otp,
      })
      .then((res) => {
        if (res.status === 202) {
          navigate("/login");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response?.data || "An unexpected error occurred");
      });
  };

  const sendOtp = (phone) => {
    axiosInstance
      .get(`${config.api.otp}?number=${phone}`)
      .then((res) => {
        if (res.status === 200) {
          setResendOtpTimer(60);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setPhoneNumberErr("");
    setPasswordErr("");
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setPasswordErr("Passwords do not match!");
      setLoading(false);
      return;
    }

    let formattedPhoneNumber = formData.phoneNumber.trim();
    if (!formattedPhoneNumber.startsWith("+91")) {
      formattedPhoneNumber = `+91${formattedPhoneNumber}`;
    }

    const updatedFormData = {
      ...formData,
      phoneNumber: formattedPhoneNumber,
    };
    setNumber(formattedPhoneNumber);
    validation()
      .then((res) => {
        if (res.email === "" && res.password === "") {
          const attributeList = [
            new CognitoUserAttribute({
              Name: "phone_number",
              Value: updatedFormData.phoneNumber,
            }),
          ];

          const username = updatedFormData.phoneNumber;

          userpool.signUp(
            username,
            updatedFormData.password,
            attributeList,
            null,
            (err, data) => {
              if (err) {
                setLoading(false);
                console.error(err.message);

                if (err.message === "User already exists") {
                  setErrorMessage(
                    "The user already exists but is not verified. If this account belongs to you, please verify it to proceed."
                  );
                }
              } else {
                setErrorMessage("");
                setStep(2);
                sendOtp(formattedPhoneNumber);
                setLoading(false);
              }
            }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  return (
    <div>
      <HeaderComponent
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        genres={genres}
      />

      {step === 1 && (
        <>
          <Container style={{ maxWidth: "500px", marginTop: "250px" }}>
            <h2 className="text-center mb-4">Sign Up</h2>
            {errorMessage && (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                {errorMessage}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                {phoneNumberErr && (
                  <p className="text-danger">{phoneNumberErr}</p>
                )}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {passwordErr && <p className="text-danger">{passwordErr}</p>}
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Form.Group>

              {errorMessage.includes("The user already ") ? (
                <Button
                  type="button"
                  className="mt-3"
                  onClick={() => {
                    setStep(2);
                    sendOtp(number);
                  }}
                >
                  Verify
                </Button>
              ) : (
                <Button type="submit" className="mt-3" disabled={loading}>
                  {loading ? "Loading..." : "Sign Up"}
                </Button>
              )}
            </Form>
          </Container>
          <div style={{ marginTop: "250px" }}>
            <Footer />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <Container style={{ maxWidth: "300px", marginTop: "300px" }}>
            {errorMessage && (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                {errorMessage}
              </Alert>
            )}
            <Form>
              <p>The OTP will be sent to this number {formData.phoneNumber}</p>
              <Form.Group controlId="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="mt-3"
                onClick={handleOtpSubmit}
              >
                Verify OTP
              </Button>
            </Form>
            {resendOtpTimer === 0 ? (
              <Button
                variant="link"
                className="mt-3"
                onClick={() => sendOtp(number)}
              >
                Resend OTP
              </Button>
            ) : (
              <p className="text-muted mt-3">
                Resend OTP in {resendOtpTimer} seconds
              </p>
            )}
          </Container>
          <div style={{ marginTop: "350px" }}>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default SignupPage;
