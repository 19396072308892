import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import HeaderComponent from "./HeaderComponent";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";

const ForgetPassword = ({ searchTerm, setSearchTerm, genres }) => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [resendOtpTimer, setResendOtpTimer] = useState(60); // Timer for OTP resend

  const navigate = useNavigate();

  // Start the OTP timer
  useEffect(() => {
    let timer;
    if (resendOtpTimer > 0) {
      timer = setInterval(() => {
        setResendOtpTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendOtpTimer]);

  const handlePhoneSubmit = () => {
    if (!phoneNumber) {
      setError("Please add the phone number");
      return;
    }
    let formattedPhoneNumber = phoneNumber.trim();
    if (!formattedPhoneNumber.startsWith("+91")) {
      formattedPhoneNumber = `+91${formattedPhoneNumber}`;
    }
    setPhoneNumber(formattedPhoneNumber);
    axiosInstance
      .get(`${config.api.otp}?number=${formattedPhoneNumber}`)
      .then((res) => {
        if (res.status === 200) {
          setStep(2);
          setResendOtpTimer(60); // Reset timer on successful OTP send
        }
      })
      .catch((err) => {
        setError(
          err.response?.data || "An unexpected error occurred or network issue"
        );
      });
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      setError("Please enter the OTP");
      return;
    }
    axiosInstance
      .post(`${config.api.otp}`, {
        phone_number: phoneNumber,
        otp: otp,
      })
      .then((res) => {
        if (res.status === 202) {
          setStep(3);
          setError("");
        }
      })
      .catch((err) => {
        setError(err.response?.data || "An unexpected error occurred");
      });
  };

  const handlePasswordSubmit = () => {
    if (!password || !confirmPassword) {
      setError("Both password fields are required.");
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    axiosInstance
      .post(`${config.api.otp}?number=${phoneNumber}`, {
        passWord: password,
      })
      .then((res) => {
        if (res.status === 200) {
          setSuccess("Password reset successfully!");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        }
      })
      .catch((err) => {
        setError(err.response?.data || "An unexpected error occurred");
      });
  };

  const resendOtp = () => {
    axiosInstance
      .get(`${config.api.otp}?number=${phoneNumber}`)
      .then((res) => {
        if (res.status === 200) {
          setResendOtpTimer(60); // Reset timer
          setError("");
          setSuccess("OTP resent successfully!");
        }
      })
      .catch((err) => {
        setError(err.response?.data || "Failed to resend OTP");
      });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  return (
    <>
      <HeaderComponent
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        genres={genres}
      />
      <div style={{ marginTop: "200px" }}></div>

      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Forget Password", path: "/forgetPassword" },
        ]}
      />
      <div style={{ marginTop: "100px" }}></div>

      {step === 1 && (
        <>
          <Container className="mt-5" style={{ maxWidth: "400px" }}>
            {error && <Alert variant="danger">{error}</Alert>}
            <h3 className="text-center">Phone Verification</h3>
            <Form>
              <Form.Group controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handlePhoneSubmit}
              >
                Send OTP
              </Button>
            </Form>
          </Container>
          <div style={{ marginTop: "340px" }}>
            <Footer />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <Container className="mt-5" style={{ maxWidth: "400px" }}>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <h3 className="text-center">OTP Verification</h3>
            <Form>
              <p>The OTP was sent to {phoneNumber}</p>
              <Form.Group controlId="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handleOtpSubmit}
              >
                Verify OTP
              </Button>
              {resendOtpTimer === 0 ? (
                <Button variant="link" className="mt-3" onClick={resendOtp}>
                  Resend OTP
                </Button>
              ) : (
                <p className="text-muted mt-3">
                  Resend OTP in {resendOtpTimer} seconds
                </p>
              )}
            </Form>
          </Container>
          <div style={{ marginTop: "300px" }}>
            <Footer />
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <Container className="mt-5" style={{ maxWidth: "400px" }}>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <h3 className="text-center">Reset Password</h3>
            <Form>
              <p>Please enter the new password</p>
              <Form.Group controlId="password">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="confirmPassword" className="mt-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handlePasswordSubmit}
              >
                Reset Password
              </Button>
            </Form>
          </Container>
          <div style={{ marginTop: "220px" }}>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default ForgetPassword;
