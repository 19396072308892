import React, { Fragment } from "react";
import HeaderComponent from "../Components/HeaderComponent";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import { useNavigate } from "react-router-dom";

const RefundPolicy = ({ searchTerm, setSearchTerm, genres }) => {
  const navigate = useNavigate();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  return (
    <Fragment>
      <HeaderComponent
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        genres={genres}
      />
      <div style={{ marginTop: "180px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Refund Policy", path: "/refund-policy" }, // Adjust path if needed
        ]}
      />
      <div className="container my-5">
        <h2>Refund and Cancellation Policy</h2>
        <p>
          This refund and cancellation policy outlines how you can cancel or
          seek a refund for a product/service that you have purchased through
          the Platform. Under this policy:
        </p>
        <ol>
          <li>
            Cancellations will only be considered if the request is made within
            3 days of placing the order. However, cancellation requests may not
            be entertained if the orders have been communicated to such sellers
            / merchant(s) listed on the Platform and they have initiated the
            process of shipping them, or the product is out for delivery. In
            such an event, you may choose to reject the product at the doorstep.
          </li>
          <li>
            In case of receipt of damaged or defective items, please report to
            our customer service team. The request would be entertained once the
            seller/merchant listed on the Platform has checked and determined
            the same at its own end. This should be reported within 3 days of
            receipt of products. In case you feel that the product received is
            not as shown on the site or as per your expectations, you must bring
            it to the notice of our customer service within 3 days of receiving
            the product. The customer service team, after looking into your
            complaint, will take an appropriate decision.
          </li>
          <li>
            In case of complaints regarding the products that come with a
            warranty from the manufacturers, please refer the issue to them.
          </li>
          <li>
            In case of any refunds approved by JINGUS SYSTEMS, it will take 7
            days for the refund to be processed to you.
          </li>
        </ol>
      </div>
      <div style={{ marginTop: "300px" }}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default RefundPolicy;
