import { configureStore } from "@reduxjs/toolkit";
import { Borrowreducers } from "./BorrowCart";
import { BuyReducer } from "./BuyCart";
import { userInformationReducer } from "./UserInformation";

export const Store = configureStore({
  reducer: {
    BorrowCart: Borrowreducers,
    BuyCart: BuyReducer,
    userInformation: userInformationReducer,
  },
});
