import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

const SortingPrice = ({ setFilteredBooks, filteredBooks, toggleSidebar }) => {
  const [selectedSorts, setSelectedSorts] = useState("");
  const sortTypes = [
    { id: "lowtoHigh", label: "Price:Low to High" },
    { id: "hightoLow", label: "Price:High to Low" },
  ];

  const handleSort = (sortId) => {
    // Clone and sort the books array
    const sortedBooks = [...filteredBooks];
    if (sortId === "lowtoHigh") {
      sortedBooks.sort((a, b) => a.sellingPrice - b.sellingPrice);
    } else if (sortId === "hightoLow") {
      sortedBooks.sort((a, b) => b.sellingPrice - a.sellingPrice);
    }

    if (toggleSidebar) {
      toggleSidebar();
    }
    setFilteredBooks(sortedBooks);
    setSelectedSorts(sortId); // Update the selected sort state here
  };

  return (
    <Row>
      <Col xs={12} className="mb-2">
        <Form.Group controlId="sortSelect">
          <Form.Select
            value={selectedSorts}
            onChange={(e) => handleSort(e.target.value)}
            style={{
              padding: "10px",
              paddingLeft: "10px",
              backgroundColor: "#f8f9fa",
              marginBottom: "10px",
              fontSize: "1rem",
              fontWeight: 500,
              color: "black",
              border: "1px solid #ccc",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <option>Sort By:</option>
            {sortTypes.map((sort) => (
              <option key={sort.id} value={sort.id}>
                {sort.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default SortingPrice;
