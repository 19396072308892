import React, { useState, useEffect } from "react";
import DisplayBook from "./DisplayBook";

const DisplayBooksGrid = ({ books }) => {
  const [columns, setColumns] = useState(6);

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth <= 960) {
        setColumns(2);
      } else if (window.innerWidth <= 1450) {
        setColumns(3);
      } else if (window.innerWidth <= 1600) {
        setColumns(4);
      } else {
        setColumns(6);
      }
    };

    // Set the initial column count
    updateColumns();

    // Add event listener to track window resize
    window.addEventListener("resize", updateColumns);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  return (
    <div style={{ paddingTop: "20px" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {books.map((book) => (
          <DisplayBook key={book.isbn} book={book} />
        ))}
      </div>
    </div>
  );
};

export default DisplayBooksGrid;
