import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BookInfo from "./BookInfo";
import Price from "./Price";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { Modal, Table } from "react-bootstrap";
import { addBuy } from "../Store/BuyCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { add } from "../Store/BorrowCart";
import config from "../Config/config";
import { getTokenFromSession } from "../services/authenticate";
import Model from "./Model";

const DisplayBook = ({ book }) => {
  const [isImgError, setIsImgError] = useState(false);
  const [show, setshow] = useState(false);
  const handleError = () => setIsImgError(true);

  const handleShow = () => {
    setshow(!show);
  };

  return (
    <>
      <Card
        style={{
          width: "100%",
          maxWidth: "14rem",
          margin: "10px",
          border: "none",
          transition: "0.3s",
          position: "relative",
        }}
      >
        <div
          onClick={handleShow}
          style={{
            width: "150px",
            height: "200px",
            margin: "0 auto",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isImgError ? "#f0f0f0" : "transparent",
            cursor: "pointer",
          }}
        >
          {!isImgError ? (
            <LazyLoadImage
              src={book.imageUrl}
              alt={book.title}
              effect="blur"
              onError={handleError}
              style={{
                width: "150px",
                height: "200px",
                objectFit: "cover",
              }}
            />
          ) : (
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
                padding: "10px",
                color: "#333",
              }}
            >
              {book.title}
            </span>
          )}
          <Badge
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              backgroundColor: "#e74c3c",
              fontSize: "0.7rem",
              padding: "5px",
              zIndex: 1,
            }}
          >
            {book.condition}
          </Badge>
        </div>

        <Card.Body style={{ padding: "10px", textAlign: "center" }}>
          <Card.Title
            style={{
              fontSize: "0.9rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {book.title}
          </Card.Title>
          <BookInfo author={book.author} />
          <Price
            lendingPricePerDay={book.lendingPricePerDay}
            sellingPrice={book.sellingPrice}
            book={book}
          />
        </Card.Body>
      </Card>
      {show && <Model book={book} show={show} handleShow={handleShow} />}
    </>
  );
};

export default DisplayBook;
