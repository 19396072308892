import React, { useState, useEffect, useMemo } from "react";
import DisplayBooksGrid from "./DisplayBooksGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SidebarMenuTwo from "../Header/SideBarMenuTwo";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import Breadcrumb from "../wrapper/Breadcrumb";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import { add, clearBorrowCart } from "../Store/BorrowCart";
import { addBuy, clearBuyCart } from "../Store/BuyCart";
import { useDispatch } from "react-redux";
import config from "../Config/config";
import axiosInstance from "../axiosInstance";
import SEO from "./SEO";
import getMetaData from "../utils/getMetaData";

const GenreBasedBook = ({
  books,
  ageCategories,
  selectedAges,
  setSelectedAges,
  genres,
  selectedGenres,
  setSelectedGenres,
  selectedConditions,
  setSelectedConditions,
  searchTerm,
  setSearchTerm,
  loading,
}) => {
  const { genre } = useParams();
  const [filteredBooks, setFilteredBooks] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const token = getTokenFromSession();
  useEffect(() => {
    const addItemsToCart = async (token) => {
      try {
        const response = await axiosInstance.get(`${config.api.cart}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        const body = await response.data;

        dispatch(clearBorrowCart());
        dispatch(clearBuyCart());

        body.forEach((items) => {
          const quantity = 1;
          if (items.transactionType === "LENDING") {
            dispatch(add({ items, quantity }));
          } else if (items.transactionType === "PURCHASE") {
            dispatch(addBuy({ items, quantity }));
          }
        });
      } catch (error) {
        if (error.response) {
          console.log("Error Status:", error.response.status);
          if (error.response.status === 401 || error.response.status === 403) {
            console.log("Unauthorized or forbidden. Attempting token refresh.");
            const refersToken = getRefreshToken();
            try {
              if (refersToken === null) {
                return;
              }
              await refreshIdToken(refersToken);
              const newToken = getTokenFromSession();
              await addItemsToCart(newToken);
            } catch (refreshError) {
              navigate("/login");
            }
          }
        } else {
          console.log("Error Message:", error.message);
        }
      }
    };

    addItemsToCart(token);
  }, [token, navigate]);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const filterBooks = (books) => {
    return books.filter((book) => {
      const matchesAge =
        selectedAges.length === 0 || selectedAges.includes(book.ageGroup);
      const matchesGenre =
        selectedGenres.length === 0 || selectedGenres.includes(book.genre);
      const matchesCondition =
        selectedConditions.length === 0 ||
        selectedConditions.includes(book.condition);
      const matchesSearchTerm =
        searchTerm.length < 3 ||
        book.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        book.author?.toLowerCase().includes(searchTerm.toLowerCase());

      return (
        matchesAge && matchesGenre && matchesCondition && matchesSearchTerm
      );
    });
  };

  useEffect(() => {
    // Automatically select the genre from the URL in the filter
    if (genre && !selectedGenres.includes(genre)) {
      setSelectedGenres([genre]);
    }
  }, [genre, selectedGenres, setSelectedGenres]);

  // Memoize filteredBooks to avoid unnecessary recalculations
  useMemo(() => {
    setFilteredBooks(filterBooks(books));
  }, [books, selectedAges, selectedGenres, selectedConditions, searchTerm]);

  useEffect(() => {
    setSelectedAges([]);
    setSelectedGenres([]);
    setSelectedConditions([]);
    setSearchTerm("");
  }, [location.pathname]);

  const handleSearch = (term) => {
    setSelectedAges([]);
    setSelectedGenres([]);
    setSelectedConditions([]);
    setSelectedGenres([]);
    setSearchTerm(term);
    navigate("/view-more/book");
  };
  const meta = getMetaData(location.pathname);
  return (
    <div>
      <SEO title={meta.title} keywords={meta.keywords} description={meta.description} url={meta.url} />
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={handleSearch} />
      <div className="menu-bar">
        <SidebarMenuTwo
          genres={genres}
          ageCategories={ageCategories}
          selectedAges={selectedAges}
          onAgeSelect={setSelectedAges}
          selectedGenres={selectedGenres}
          onGenreSelect={setSelectedGenres}
          selectedConditions={selectedConditions}
          onConditionSelect={setSelectedConditions}
          setFilteredBooks={setFilteredBooks}
          filteredBooks={filteredBooks}
        />
      </div>
      <div style={{ marginTop: "180px" }}>
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            {
              label: `${selectedGenres}`,
              path: `genre-based-books/${selectedGenres}`,
            },
          ]}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          padding: "20px",
          width: "100%",
          height: "350px",
        }}
      >
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            overflowY: "auto",
          }}
        >
          <DisplayBooksGrid books={filteredBooks} />
        </div>
      </div>
    </div>
  );
};

export default GenreBasedBook;
