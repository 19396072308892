import React, { useState, useEffect, useContext } from "react";
import { Table, Spinner } from "react-bootstrap";
import "../css/Payment.css";
import HeaderComponent from "./HeaderComponent";
import { useNavigate } from "react-router-dom";
import Footer from "../assets/Footer";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import config from "../Config/config";
import { useDispatch } from "react-redux";
import { clearBorrowCart } from "../Store/BorrowCart";
import { clearBuyCart } from "../Store/BuyCart";
import { AmountContext } from "../Context/AmountContext";

const PaymentStatus = ({ genres, searchTerm, setSearchTerm }) => {
  const { orderAmount, walletBalance, remainingAmount } = useContext(
    AmountContext
  );
  const navigate = useNavigate();
  const [merchantTranId, setMerchantTranId] = useState();
  const [orderId, setOrderId] = useState();
  const [transaction, setTransaction] = useState({
    actualPaymentInPaisa: 0,
    isPaymentSuccessful: false,
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleSearch = (term) => {
    setSearchTerm(term);
    navigate("/view-more/book");
  };

  const fetchPaymentStatus = async () => {
    const token = getTokenFromSession();
    try {
      const response = await fetch(`${config.api.payment}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          merchantTranId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTransaction({
        actualPaymentInPaisa: data.amountInPaisa,
        isPaymentSuccessful: data.jingusStatus,
      });
      dispatch(clearBorrowCart());
      dispatch(clearBuyCart());
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          refreshIdToken(refersToken)
            .then(() => fetchPaymentStatus())
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/payment_status");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    } finally {
      setLoading(false); // Hide spinner after fetch completes
    }
  };

  useEffect(() => {
    if (!orderAmount) {
      const merchantTran = localStorage.getItem("merchantTransactionId");
      setMerchantTranId(merchantTran);
    } else {
      setLoading(false);
      localStorage.removeItem("merchantTransactionId");
    }

    const order = localStorage.getItem("orderId");
    setOrderId(order);
  }, []);

  useEffect(() => {
    if (orderId && merchantTranId) {
      fetchPaymentStatus();
    }
  }, [orderId, merchantTranId]);

  return (
    <>
      <div>
        <HeaderComponent
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          genres={genres}
        />
      </div>
      <div className="payment-container">
        <h1 className="payment-header">Payment Status</h1>
        {loading ? (
          <div className="spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : orderAmount ? (
          <Table bordered responsive className="custom-table">
            <tbody>
              <tr>
                <th>Order Amount</th>
                <td>₹{orderAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Wallet Balance</th>
                <td>₹{walletBalance.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Remaining Wallet Amount</th>
                <td>₹{remainingAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <th>OrderStatus</th>
                <td>Placed</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          // Render original table
          <Table bordered responsive className="custom-table">
            <tbody>
              <tr>
                <th>Merchant Tran ID</th>
                <td>{merchantTranId}</td>
              </tr>
              <tr>
                <th>Order ID</th>
                <td>{orderId}</td>
              </tr>
              <tr>
                <th>Actual Payment (₹)</th>
                <td>
                  ₹
                  {(parseInt(transaction.actualPaymentInPaisa) / 100).toFixed(
                    2
                  )}
                </td>
              </tr>
              <tr>
                <th>Payment Status</th>
                <td
                  style={{
                    color: transaction.isPaymentSuccessful ? "green" : "red",
                  }}
                >
                  {transaction.isPaymentSuccessful === "PAYMENT_SUCCESS"
                    ? "Successful"
                    : "Failed"}
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default PaymentStatus;
