import React from "react";
import { Form } from "react-bootstrap";

const Conditions = ({ selectedConditions, onSelect, toggleSidebar }) => {
  const conditions = ["NEW", "Used"];

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      onSelect((prev) => [...prev, value]);
    } else {
      onSelect((prev) => prev.filter((condition) => condition !== value));
    }
    if (toggleSidebar) {
      toggleSidebar();
    }
  };

  return (
    <Form>
      {conditions.map((condition) => (
        <Form.Check
          key={condition}
          type="checkbox"
          id={`condition-${condition}`}
          value={condition}
          checked={selectedConditions.includes(condition)}
          onChange={handleChange}
          label={condition}
        />
      ))}
    </Form>
  );
};

export default Conditions;
