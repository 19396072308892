import React, { useEffect, useState, Fragment } from "react";
import {
  getRefreshToken,
  getTokenFromSession,
  refreshIdToken,
} from "../services/authenticate";
import Header from "../Header/Header";
import SearchBar from "./SearchBar";
import Breadcrumb from "../wrapper/Breadcrumb";
import Footer from "../assets/Footer";
import { useNavigate } from "react-router-dom";
import { Table, Container, Row, Col, Button, Spinner } from "react-bootstrap";
import axiosInstance from "../axiosInstance";
import config from "../Config/config";
import { format } from "date-fns";

export const Orders = () => {
  const [orders, setOrders] = useState([]); // Initialize as an empty array
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true); // Start loading
        const token = getTokenFromSession();
        const response = await axiosInstance.get(`${config.api.orders}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        const data = response.data;
        setOrders(data || []);
      } catch (error) {
        if (error.response) {
          console.error("Error Response:", error.response);

          if (error.response.status === 401 || error.response.status === 403) {
            console.error("401 Unauthorized - Token might have expired.");
            const refersToken = getRefreshToken();
            try {
              await refreshIdToken(refersToken);
              fetchOrders(); // Retry fetching orders
            } catch (refreshError) {
              console.error("Token Refresh Failed:", refreshError);
              sessionStorage.setItem("intendedPage", "/order");
              navigate("/login");
            }
          } else {
            console.error("Unexpected Status:", error.response.status);
          }
        } else {
          console.error("Request Error:", error.message);
        }
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchOrders();
  }, [navigate]);

  const fetchInvoice = async (orderId) => {
    try {
      const token = getTokenFromSession();
      const response = await axiosInstance.get(
        `${config.api.orders}?orderId=${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: ` ${token}`,
          },
        }
      );
      const url = response.data;
      window.open(url, "_blank");
    } catch (error) {
      if (error.response) {
        console.log("Error Status:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
          const refersToken = getRefreshToken();
          refreshIdToken(refersToken)
            .then(() => fetchInvoice(orderId))
            .catch(() => {
              sessionStorage.setItem("intendedPage", "/order");
              navigate("/login");
            });
        }
      } else {
        console.log("Error Message:", error.message);
      }
    }
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    // const formattedDate = date.toLocaleString("en-US", {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   second: "2-digit",
    //   hour12: true,
    // });

    return format(date, "yyyy-MM-dd hh:mm:ss a");
  }

  return (
    <Fragment>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div style={{ marginTop: "150px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Order", path: "/order" },
        ]}
      />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : orders.length === 0 ? (
        <div className="text-center mt-5 mb-5" style={{ minHeight: "50vh" }}>
          No order is found
        </div>
      ) : (
        <Container className="py-5">
          <h3 className="text-center mb-4">Your Orders</h3>
          {orders.map((order) => {
            let sno = 0;
            let totalCautionDeposit = 0;
            let totalBuyPrice = 0;

            return (
              <div key={order.orderId} className="mb-5">
                <Row className="align-items-center mb-3">
                  <Col xs={12} md={8}>
                    <h5>Order ID: {order.orderId}</h5>
                    <p>Status: {order.orderStatus}</p>
                  </Col>
                  <Col xs={12} md={4} className="text-end">
                    <p>
                      <span>Order date: </span>
                      {convertUnixTimestampToDate(order.orderTimestamp)}
                    </p>
                    {order.orderInvoice && (
                      <Button
                        variant="primary"
                        onClick={() => fetchInvoice(order.orderId)}
                      >
                        Get Invoice
                      </Button>
                    )}
                  </Col>
                </Row>
                <Table responsive bordered hover>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Borrow Price</th>
                      <th>Caution Deposit</th>
                      <th>Buy Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Handle Lending Orders */}
                    {order.lendingOrderList &&
                      order.lendingOrderList.map((orderItem) => {
                        totalCautionDeposit += orderItem.cautionDeposit || 0;
                        return (
                          <tr key={orderItem.isbn}>
                            <td>{++sno}</td>
                            <td>
                              <img
                                src={`${config.imageUrlBase}${orderItem.isbn}.jpg`}
                                alt={orderItem.title || "No Title"}
                                style={{ width: "50px", height: "auto" }}
                              />
                            </td>
                            <td>{orderItem.title || "Unknown Title"}</td>
                            <td>₹{orderItem.lendingPricePerDay}</td>
                            <td>₹{orderItem.cautionDeposit}</td>
                            <td>-</td>
                          </tr>
                        );
                      })}
                    {/* Handle Sale Orders */}
                    {order.saleOrderList &&
                      order.saleOrderList.map((orderItem) => {
                        totalBuyPrice += orderItem.sellingPrice || 0;
                        return (
                          <tr key={orderItem.isbn}>
                            <td>{++sno}</td>
                            <td>
                              <img
                                src={`${config.imageUrlBase}${orderItem.isbn}.jpg`}
                                alt={orderItem.title || "No Title"}
                                style={{ width: "50px", height: "auto" }}
                              />
                            </td>
                            <td>{orderItem.title || "Unknown Title"}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>₹{orderItem.sellingPrice}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Row className="justify-content-between mt-3">
                  <Col>
                    <strong>Caution Deposit: </strong> ₹{totalCautionDeposit}
                  </Col>
                  <Col className="text-end">
                    <strong>Buy Total: </strong> ₹{totalBuyPrice}
                  </Col>
                  <Col>
                    <strong>Shipping Amount:</strong>₹{order.shippingAmount}
                  </Col>
                  <Col className="text-end">
                    <strong>Grand Total: </strong> ₹
                    {totalCautionDeposit + totalBuyPrice + order.shippingAmount}
                  </Col>
                </Row>
              </div>
            );
          })}
        </Container>
      )}
      <div style={{ marginTop: "160px" }}>
        <Footer />
      </div>
    </Fragment>
  );
};
