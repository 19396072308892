import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../css/SideBarMenuOne.css";

const SideBarMenuOne = ({ genres }) => {
  const location = useLocation();
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container fluid className="navbar-container">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar-toggle"
          style={{ background: "white" }}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="navbar-collapse-custom"
        >
          <Nav className="me-auto">
            <Nav.Item className="nav-item-first"></Nav.Item>
            <Nav.Item className="nav-item-spacing">
              <Link to={`/view-more/book`} className="nav-link-custom">
                AllBooks
              </Link>
            </Nav.Item>
            {genres.map((genre) => (
              <Nav.Item key={genre} className="nav-item-spacing">
                <Link
                  to={`/genre-based-books/${genre}`}
                  className="nav-link-custom"
                >
                  {genre}
                </Link>
              </Nav.Item>
            ))}
          </Nav>
          {location.pathname !== "/request" && (
            <Nav.Item className="nav-item-spacing">
              <Link to="/request" className="nav-link-custom">
                RequestBook
              </Link>
            </Nav.Item>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SideBarMenuOne;
